import { useEffect, useState } from "react";
import { getReports } from "../../services/report.service";
import useAlerts from "../../hooks/useAlerts";
import { groupReports } from "../../utils/statsUtils";
import ICardWithResults from "../../types/ICardWithResults";
import UserReportsList from "../common/ride/ReportsList";

export default function RaportsAll(): JSX.Element {
  const { error } = useAlerts();

  const [reports, setReports] = useState<ICardWithResults[]>();

  useEffect(() => {
    getReports()
      .then(({ data }) => {
        setReports(groupReports(data));
      })
      .catch((err) => {
        if (err.response.status !== 401) {
          error();
        }
      });
  }, []);

  return <UserReportsList reports={reports} />;
}
