import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Paper, Skeleton, Stack, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link, useNavigate } from "react-router-dom";
import { R_ELEARNING_VIEW, R_INDEX } from "../../config/routes";
import useAlerts from "../../hooks/useAlerts";
import { getElearnings } from "../../services/elearning.service";
import { useTranslation } from "react-i18next";

const ElearningAll = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { error } = useAlerts();

  const [elearing, setElearning] = useState<any>();

  useEffect(() => {
    let isMounted = false;

    getElearnings()
      .then((data) => {
        if (isMounted) return;
        setElearning(data.data[0]);
      })
      .catch((err) => {
        console.error(err);
        error();
      });

    return () => {
      isMounted = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <Box className="test_view">
        {typeof elearing !== "object" && (
          <Paper sx={{ p: { xs: 2, sm: 3 }, mt: 1, mb: 4 }}>
            <Skeleton animation="wave" />
          </Paper>
        )}

        {elearing?.length === 0 && (
          <Paper sx={{ p: { xs: 2, sm: 3 }, mt: 1, mb: 4 }}>
            <Typography>{t("Brak szkoleń do wyświetlenia")}</Typography>
          </Paper>
        )}

        {elearing?.length !== 0 &&
          elearing?.map((training: any) => (
            <Paper
              sx={{ p: { xs: 2, sm: 3 }, mt: 1, mb: 4 }}
              className="test_list"
              key={training.id}
            >
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Typography component="h3">{training.name}</Typography>
                <Link
                  to={R_ELEARNING_VIEW(training.id)}
                  style={{ margin: 0, width: "auto" }}
                  className="test_button"
                >
                  {t("Zobacz")}
                </Link>
              </Stack>
            </Paper>
          ))}
      </Box>
    </Box>
  );
};

export default ElearningAll;
