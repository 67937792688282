import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Button, MobileStepper, Paper, Skeleton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link, useNavigate, useParams } from "react-router-dom";
import { R_INDEX } from "../../config/routes";
import useAlerts from "../../hooks/useAlerts";
import { getElearning } from "../../services/elearning.service";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import PageTopLayout from "./layouts/PageTopLayout";
import { useTranslation } from "react-i18next";
import PageLeftLayout from "./layouts/PageLeftLayout";
import PageRightLayout from "./layouts/PageRightLayout";
import IElearningPage from "../../types/IElearningPage";

const ElearningView = (): JSX.Element => {
  const { t } = useTranslation();
  const { error } = useAlerts();
  const navigate = useNavigate();
  const { id } = useParams();

  const [elearning, setElearning] = useState<any>();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (id) {
      getElearning(id)
        .then(({ data }) => setElearning(data))
        .catch((err) => error(t("Ups, coś poszło nie tak")));
    }
  }, []);

  function getPageLayout(page: IElearningPage) {
    switch (page.layout) {
      case 1:
        return <PageTopLayout page={page} />;
      case 2:
        return <PageLeftLayout page={page} />;
      case 3:
        return <PageRightLayout page={page} />;
      default:
        return <PageTopLayout page={page} />;
    }
  }

  return (
    <Box>
      <Box className="test_view">
        <Box
          sx={{
            mt: 1,
            mb: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <Link to="" className="back_button" onClick={() => navigate(-1)}>
            <ArrowBackIcon /> {t("Wstecz")}
          </Link>
          <Link to={R_INDEX} className="back_button">
            {t("Strona Główna")} <ArrowForwardIcon />
          </Link>
        </Box>

        <Paper sx={{ p: { xs: 2, sm: 3 }, mt: 1, mb: 4 }} className="test_list">
          {!elearning && (
            <Box className="test_list_box">
              <Skeleton animation="wave" sx={{ width: "100%" }} />
            </Box>
          )}
          {elearning && (
            <Box className="test_list_box">
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                <Typography component="h3">
                  {t("Prezentacja")}: <strong>{elearning.name}</strong>
                </Typography>
              </Box>
            </Box>
          )}
        </Paper>
        {elearning && (
          <Paper sx={{ p: 0, mt: 1, mb: 4 }} className="test_list">
            <MobileStepper
              variant="dots"
              steps={elearning.pages.length}
              position="static"
              activeStep={activeStep}
              sx={{ flexGrow: 1 }}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === elearning.pages.length - 1}
                >
                  {t("Następny")}
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  <KeyboardArrowLeft />
                  {t("Poprzedni")}
                </Button>
              }
            />

            {getPageLayout(elearning.pages[activeStep])}

            <MobileStepper
              variant="dots"
              steps={elearning.pages.length}
              position="static"
              activeStep={activeStep}
              sx={{ flexGrow: 1 }}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === elearning.pages.length - 1}
                >
                  {t("Następny")}
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  <KeyboardArrowLeft />
                  {t("Poprzedni")}
                </Button>
              }
            />
          </Paper>
        )}
      </Box>
    </Box>
  );
};

export default ElearningView;
