export const getFormattedDate = (
  dateString?: string | null
): { date: string; shortDate: string; time: string } => {
  if (!dateString) {
    return {
      date: "",
      shortDate: "",
      time: ""
    };
  }
  const date = new Date(dateString);

  return {
    date: `${date.toLocaleDateString("pl-PL", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    })}`,
    shortDate: `${date.toLocaleDateString("pl-PL", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit"
    })}`,
    time: `${date.toLocaleTimeString(["pl-PL"], {
      hour: "2-digit",
      minute: "2-digit"
    })}`
  };
};
