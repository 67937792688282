import IMenuItem from "../types/IMenuItem";
import {
  R_COMPANY_RIDES,
  R_COMPANY_TESTS,
  R_ELEARNING,
  R_INDEX,
  R_INDEX_ADMIN,
  R_QUIZS,
  R_REPORTS,
  R_TESTS
} from "./routes";

// Role: User

export const menu: IMenuItem[] = [
  {
    name: "Strona Główna",
    route: R_INDEX
  },
  {
    name: "Raporty",
    route: R_REPORTS
  },
  {
    name: "Egzaminy",
    route: R_TESTS
  },
  {
    name: "E-learning",
    route: R_ELEARNING
  },
  {
    name: "Quizy",
    route: R_QUIZS
  }
];

// Role: Company

export const menuCompanyOwner: IMenuItem[] = [
  {
    name: "Uczestnicy",
    route: R_INDEX
  },
  {
    name: "Teoria",
    route: R_COMPANY_TESTS
  },
  {
    name: "Praktyka",
    route: R_COMPANY_RIDES
  }
];

// Role: Admin

export const menuAdmin: IMenuItem[] = [
  {
    name: "Firmy",
    route: R_INDEX_ADMIN
  },
  ...menuCompanyOwner
];

// Role: Trainer

export const menuTrainer: IMenuItem[] = [
  {
    name: "Strona Główna",
    route: R_INDEX
  },
  {
    name: "Ewaluacje",
    route: R_REPORTS
  }
];
