import { Avatar, IconButton, Paper } from "@mui/material";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { R_SETTINGS } from "../config/routes";

export const AvatarImage = () => {
  return (
    <Avatar>
      <Paper>
        <IconButton href={R_SETTINGS}>
          <PermIdentityIcon />
        </IconButton>
      </Paper>
    </Avatar>
  );
};
