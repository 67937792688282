import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Paper, Typography } from "@mui/material";
import AccessTimeFilledOutlinedIcon from "@mui/icons-material/AccessTimeFilledOutlined";
import Person from "@mui/icons-material/Person";
import Percent from "@mui/icons-material/Percent";
import IReport from "../../../../types/IReport";
import { getFormattedDate } from "../../../../utils/dateUtils";
import styled from "@emotion/styled";

const StyledItem = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  "&:first-of-type": {
    paddingTop: 0
  },
  "& strong": {
    fontSize: '1rem'
  }
}));

const RideHeader = ({ report }: { report: IReport }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Paper>
      <Grid container p={2} spacing={2}>
        <StyledItem item xs={12}>
          <AccessTimeFilledOutlinedIcon color="primary" />
          <Typography sx={{ ml: 0.8 }}>
            {t("Rozpoczęto")}: <strong>{getFormattedDate(report.createdAt).time}</strong>
          </Typography>
        </StyledItem>

        {report.updatedAt !== null && (
          <StyledItem item xs={12}>
            <AccessTimeFilledOutlinedIcon color="secondary" />
            <Typography sx={{ ml: 0.8 }}>
              {t("Ostatnia aktualizacja")}:{" "}
              <strong>{getFormattedDate(report.updatedAt).time}</strong>
            </Typography>
          </StyledItem>
        )}

        <StyledItem item xs={12}>
          <Person color="primary" />
          <Typography sx={{ ml: 0.8 }}>
            <strong>{report.user.name}</strong> ({report.user.email})
          </Typography>
        </StyledItem>

        {!!report.result && (
          <StyledItem item xs={12}>
            <Percent color="primary" />
            <Typography sx={{ ml: 0.8 }}>
              {t("Ocena całościowa")}: <strong>{report.result}%</strong>
            </Typography>
          </StyledItem>
        )}
      </Grid>
    </Paper>
  );
};

export default RideHeader;
