import React from "react";
import IElearningPage from "../../../types/IElearningPage";
import { Box, Grid } from "@mui/material";
import MediaDisplay from "../../../components/MediaDisplay";

export default function PageLeftLayout({ page }: { page: IElearningPage }) {
  return (
    <Box p={2}>
      <Grid container spacing={2}>
        {page.media && (
          <Grid item xs={6}>
            <MediaDisplay media={page.media} />
          </Grid>
        )}
        <Grid xs={6} item dangerouslySetInnerHTML={{ __html: page.content }} />
      </Grid>
    </Box>
  );
}
