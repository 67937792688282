import { Box, Container } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  R_FIRST_LOGIN,
  R_INDEX,
  R_INDEX_ADMIN,
  R_REMIND,
} from "../../config/routes";
import useAlerts from "../../hooks/useAlerts";
import {
  authLogin,
  sendRemind,
  sendRemindPassword,
} from "../../services/auth.service";
import IAuth from "../../types/IAuth";
import LoginForm from "./components/LoginForm";
import PasswordForm from "./components/PasswordForm";
import RemindForm from "./components/RemindForm";
import systemLogo from "../../utils/systemLogo";
import { useTranslation } from "react-i18next";
import {
  setCompany,
  setIsAuthenticated,
  setRefreshTokenExpirationTimestamp,
  setTokenUser,
} from "../../utils/tokenUtils";

export default function Login() {
  const { t } = useTranslation();

  const { error, info } = useAlerts();

  const imageLogo = systemLogo;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IAuth>({});

  const navigate = useNavigate();
  const location = useLocation().pathname;

  let token = "";

  if (location.split("/")[1].toLocaleLowerCase() === "remind") {
    token = location.replace(R_REMIND, "").substring(1);
  }

  function handleLoading(data: boolean) {
    setLoading(data);
  }

  function handleSubmit(data: IAuth) {
    authLogin(data)
      .then(({ data }) => {
        if (data.user) {
          setTokenUser(data.user);
          setIsAuthenticated(true);
        }

        if (data.company?.id) {
          setCompany(data.company);
        }

        if (data.refreshTokenExpirationTimestamp) {
          setRefreshTokenExpirationTimestamp(
            data.refreshTokenExpirationTimestamp
          );
        }

        if (data.user.role === "Admin") {
          navigate(R_INDEX_ADMIN);
        } else {
          navigate(R_INDEX);
        }
      })
      .catch((err) => {
        console.error(err);
        error(t("Błędne dane logowania"));
        setLoading(false);
      });
  }

  function handleSubmitRemind(data: IAuth) {
    sendRemind(data)
      .then(() => {
        setData({});
      })
      .catch((err) => {
        console.error(err);
      });

    info(t("Wysłano prośbę o wygenerowanie nowego hasła"));
  }

  function handleNewPassword(password: IAuth) {
    if (token) {
      const data = {
        password: password.password,
        token: token
      };

      sendRemindPassword(data as IAuth)
        .then(() => {
          navigate(R_INDEX);
          info(t("Zmieniono hasło"));
        })
        .catch((err) => {
          console.error(err);
          navigate(R_INDEX);
          error();
        });
    }
  }

  // function handleSubmitFirstLogin(data: IPasswordRecovery) {

  //     delete data.repeatPassword;

  //     changePassword(data)
  //         .then(() => {
  //             navigate(R_INDEX);
  //             setLoading(false);
  //             success('Zaktualizowano hasło');
  //         })
  //         .catch(err => {
  //             console.error(err);
  //             error('Nie zaktualizowano hasła');
  //         })
  // }

  return (
    <Box width="100%">
      <Container maxWidth="xs" sx={{ position: { xs: "relative", sm: "inherit" } }}>
        {location === R_REMIND && (
          <RemindForm data={data} setData={setData} onSubmit={handleSubmitRemind} />
        )}
        {token.length > 0 && (
          <PasswordForm data={data} setData={setData} onSubmit={handleNewPassword} />
        )}
        {/* {location.pathname === R_FIRST_LOGIN &&
                    <FirstPasswordForm onSubmit={handleSubmitFirstLogin} />
                } */}
        {location !== R_REMIND && token.length === 0 && location !== R_FIRST_LOGIN && (
          <LoginForm
            data={data}
            setData={setData}
            onSubmit={handleSubmit}
            setLoading={handleLoading}
            loading={loading}
          />
        )}
        <Box
          sx={{
            maxHeight: { xs: "120px", sm: "150px" },
            width: "auto",
            position: "absolute",
            bottom: { xs: "none", sm: "1.5rem" },
            right: 0,
            left: 0,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "2rem",
            textAlign: "center"
          }}
          className="logoBox"
        >
          <img src={imageLogo} alt="Logo SafeDrive" />
        </Box>
      </Container>
    </Box>
  );
}
