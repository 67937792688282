import axios, { AxiosPromise } from "axios";
import IReport from "../types/IReport";
import ITest from "../types/ITest";
import IUser from "../types/IUser";
import ICompany from "../types/ICompany";
import ICard from "../types/ICard";
import ITestResult from "../types/ITestResult";
import IGroupWithCounts from "../types/IGroupWithCompany";
import ITestWithCompanyGroupsAndResults from "../types/ITestWithCompanyGroupsAndResults";
import ITestWithResults from "../types/ITestWithResults";
import IUserWithResultsAndRides from "../types/IUserWithResultsAndRides";

export const getCompanyGroups = (): AxiosPromise<IGroupWithCounts[]> =>
  axios.get(`/api/company/groups`);

export const getCompanyTests = (): AxiosPromise<ITestWithCompanyGroupsAndResults[]> =>
  axios.get("/api/company/tests");

export const getCompanyUsers = (): AxiosPromise<IUser[]> => axios.get("/api/company/users");

export const getCompanyUser = (user_id: number | string): AxiosPromise<IUserWithResultsAndRides> =>
  axios.get(`/api/company/users/${user_id}`);

export const getCompanyCards = (): AxiosPromise<ICard[]> => axios.get("/api/company/cards");

export const getCompanyGroupDetails = (group_id: number | string): AxiosPromise =>
  axios.get(`/api/group/${group_id}`);

export const getCompanyTestStats = (
  group_id: number | string,
  test_id: number | string
): AxiosPromise<ITestWithResults> => axios.get(`/api/stats/group/${group_id}/tests/${test_id}`);

export const getCompanyCardDetails = (card_id: number | string): AxiosPromise =>
  axios.get(`/api/stats/rides/${card_id}`);
export const getCompanyCardList = (card_id: number | string): AxiosPromise =>
  axios.get(`/api/stats/rides/${card_id}/list`);

export const getCompanies = (): AxiosPromise<[ICompany[], number]> => axios.get("/companies");

export const setCompanyToken = (company_id: number | string): AxiosPromise =>
  axios.post(`/auth/pick-company`, { company: company_id });

export const getCompanyUserRides = (userId: number | string): AxiosPromise<IReport[]> =>
  axios.get(`/api/cards/rides/user/${userId}`);
export const getCompanyUserTests = (userId: number | string): AxiosPromise<ITest[]> =>
  axios.get(`/api/tests/user/${userId}`);
export const getCompanyUserTestsResults = (
  userId: number | string
): AxiosPromise<(ITestResult & { test: ITest })[]> => //todo: refactor return type
  axios.get(`/api/tests/user/${userId}/results`);
// export const getCompanyUserTestResults = (testId: number | string): AxiosPromise => axios.get(`/api/tests/${testId}/results/company`)
export const getCompanyTestResultsByUser = (
  userId: number | string,
  testId: number | string
): AxiosPromise => axios.get(`/api/tests/${testId}/results/users/${userId}`);
export const getCompanyUserTestResult = (resultId: number | string): AxiosPromise =>
  axios.get(`/api/tests/results/${resultId}`);
