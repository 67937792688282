import React from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, Paper, TextField } from "@mui/material";
import ICard from "../../../types/ICard";

const CardsPicker = ({
  cards,
  pickedCard,
  onPickCard,
  disabled
}: {
  pickedCard?: ICard;
  disabled: boolean;
  cards: ICard[];
  onPickCard: (user: ICard | null) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Paper sx={{ p: 1 }}>
      <Autocomplete
        disabled={disabled}
        options={cards.sort((a: ICard, b: ICard) =>
          a.name === b.name ? 0 : a.name > b.name ? 1 : -1
        )}
        onChange={(event, newValue) => {
          if (typeof newValue === "object") {
            onPickCard(newValue);
          }
        }}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        value={pickedCard || null}
        getOptionLabel={(users) => users.name}
        renderInput={(params) => <TextField {...params} label={t("Wybierz kartę")} />}
        noOptionsText={t("Nie ma takiej karty")}
      />
    </Paper>
  );
};

export default CardsPicker;
