import {
  Button,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import ArrayLoadingSkeleton from "../../../components/ArrayLoadingSkeleton";
import { useTranslation } from "react-i18next";
import { getStatsColorByResult } from "../../../utils/statsUtils";
import { useNavigate } from "react-router-dom";
import { R_COMPANY_TESTS_TEST } from "../../../config/routes";
import ITestWithCompanyGroupsAndResults from "../../../types/ITestWithCompanyGroupsAndResults";

export default function CompanyTestsList({
  tests
}: Readonly<{ tests: ITestWithCompanyGroupsAndResults[] | undefined }>) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ArrayLoadingSkeleton emptyText={t("Brak egzaminów do wyświetlenia")} data={tests}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("Nazwa")}</TableCell>
              <TableCell align="center">{t("Prób")}</TableCell>
              <TableCell>{t("Wyniki")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tests
              ?.filter((test: any) => test.timeOut > 0)
              ?.map((test: any) => (
                <TableRow key={test.id}>
                  <TableCell>
                    <Typography variant={"body1"}>{test.name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="button" color="primary">
                      {test.attempts ?? 0}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">
                      {t("Średnia")} {" - "}
                      <strong style={{ color: getStatsColorByResult(test?.avg ?? 0) }}>
                        {Math.round(test.avg ?? 0)}%
                      </strong>
                    </Typography>
                    <Tooltip
                      describeChild
                      title={test.highestResult?.user?.name}
                      arrow
                      placement="right"
                    >
                      <Link color={"secondary"}>
                        <Typography variant="body2">
                          {t("Najwyższy wynik")} -{" "}
                          <strong style={{ color: getStatsColorByResult(test?.max ?? 0) }}>
                            {test.max ?? 0}%
                          </strong>
                        </Typography>
                      </Link>
                    </Tooltip>
                    <Tooltip title={test.lowestResult?.user?.name} arrow placement="right">
                      <Link color={"secondary"}>
                        <Typography variant="body2">
                          {t("Najniższy wynik")} -{" "}
                          <strong style={{ color: getStatsColorByResult(test?.min ?? 0) }}>
                            {test.min ?? 0}%
                          </strong>
                        </Typography>
                      </Link>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={() => navigate(R_COMPANY_TESTS_TEST(test?.groups[0]?.id, test.id))}
                    >
                      {t("Szczegóły")}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ArrayLoadingSkeleton>
  );
}
