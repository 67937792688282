import { Backdrop, CircularProgress, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { R_RIDE } from "../../config/routes";
import React, { useEffect, useState } from "react";
import { getCards, getCardsUsers, startRide } from "../../services/card.service";
import useAlerts from "../../hooks/useAlerts";
import ICard from "../../types/ICard";

import { useTranslation } from "react-i18next";
import PaperStepper from "../../components/PaperStepper";
import ArrayLoadingSkeleton from "../../components/ArrayLoadingSkeleton";
import UserPicker from "./components/UserPicker";
import IUser from "../../types/IUser";
import CardsPicker from "./components/CardsPicker";
import PickersSummary from "./components/PickersSummary";
import IUserWithCompany from "../../types/IUserWithCompany";

const TrainerHome = (): JSX.Element => {
  const { t } = useTranslation();
  const { error, info } = useAlerts();
  const navigate = useNavigate();

  const [users, setUsers] = useState<IUserWithCompany[]>();
  const [cards, setCards] = useState<ICard[]>();

  const [pickedUser, setPickedUser] = useState<IUser>();
  const [pickedCard, setPickedCard] = useState<ICard>();

  const [loading, setLoading] = useState<boolean>(false);

  const steps = [t("Wybierz uczestnika"), t("Wybierz kartę ewaluacji"), t("Rozpocznij ewaluację")];

  const activeStep = (() => {
    if (!!pickedUser && !!pickedCard) return 2;
    if (!!pickedUser) return 1;
    return 0;
  })();

  const handleComplete = () => {
    setLoading(true);
    const data = { user: pickedUser?.id, card: pickedCard?.id };

    startRide(data)
      .then(({ data }) => {
        navigate(R_RIDE(data.id));
        info("Rozpoczęto ewaluację");
      })
      .catch((err) => {
        error(t("Wystąpił błąd podczas rozpoczynania ewaluacji"));
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCardsUsers()
      .then(({ data }) => {
        setUsers(data.filter((user) => user.role !== "Trainer"));
      })
      .catch(() => {
        error();
      });

    getCards()
      .then(({ data }) => {
        setCards(data[0]);
      })
      .catch(() => {
        error();
      });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PaperStepper steps={steps} step={activeStep} />
      </Grid>
      <Grid item xs={12}>
        <ArrayLoadingSkeleton data={users} emptyText={t("Brak uczestników do wyświetlenia")}>
          <UserPicker
            users={users ?? []}
            onPickUser={(user) => {
              setPickedUser(user ?? undefined);
            }}
          />
        </ArrayLoadingSkeleton>
      </Grid>
      <Grid item xs={12}>
        <ArrayLoadingSkeleton data={cards} emptyText={t("Brak kart ewaluacji do wyświetlenia")}>
          <CardsPicker
            disabled={activeStep < 1}
            cards={cards ?? []}
            pickedCard={pickedCard}
            onPickCard={(card) => {
              setPickedCard(card ?? undefined);
            }}
          />
        </ArrayLoadingSkeleton>
      </Grid>
      <Grid item xs={12}>
        <PickersSummary
          pickedCard={pickedCard}
          pickedUser={pickedUser}
          onReset={() => {
            setPickedCard(undefined);
            setPickedUser(undefined);
          }}
          onConfirm={handleComplete}
        />
      </Grid>

      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default TrainerHome;
