import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import LabelImportantOutlinedIcon from "@mui/icons-material/LabelImportantOutlined";

export const NavItem = ({ title, href }: { title: string; href: string }) => {
  const pathname = "/" + window.location.pathname.split("/")[1];
  const active = pathname === href;

  return (
    <ListItemButton selected={active} href={href}>
      <ListItemIcon>
        <LabelImportantOutlinedIcon />
      </ListItemIcon>
      <ListItemText>{title}</ListItemText>
    </ListItemButton>
  );
};
