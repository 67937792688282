import axios, { AxiosPromise } from "axios";
import ITest from "../types/ITest";

export const getTests = (): AxiosPromise<ITest[]> => axios.get(`/api/tests`);
export const getQuizes = (): AxiosPromise<ITest[]> => axios.get(`/api/tests?quiz=true`);

export const getTest = (testId: string | number): AxiosPromise<ITest> =>
  axios.get(`/api/tests/${testId}`);
//TODO: add result class
export const getTestResults = (testId: string | number): AxiosPromise<any> =>
  axios.get(`/api/tests/${testId}/results`);
export const getFinishTest = (resultId: string | number): AxiosPromise<any> =>
  axios.get(`/api/tests/results/${resultId}`);

export const startTest = (testId: string | number): AxiosPromise<any> =>
  axios.post(`/api/tests/${testId}/new`);

export const answerTest = (result: any, resultId: number | string): AxiosPromise =>
  axios.post(`/api/tests/${resultId}/answer`, { answers: result });
export const answerTestFinal = (result: any, resultId: number | string): AxiosPromise =>
  axios.post(`/api/tests/${resultId}/finish`, { answers: result });
