import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Button,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { getCompanies, setCompanyToken } from "../../services/company.service";
import useAlerts from "../../hooks/useAlerts";
import ICompany from "../../types/ICompany";
import { getCompany, setCompany } from "../../utils/tokenUtils";
import { useTranslation } from "react-i18next";

const IndexAdmin = (): JSX.Element => {
  const { t } = useTranslation();

  const { error, success } = useAlerts();
  const [companies, setCompanies] = useState<ICompany[]>();
  const [currentCompany, setCurrentCompany] = useState(getCompany());

  function handleChangeToken(company_id: number | string) {
    setCompanyToken(company_id)
      .then(({ data }) => {
        setCompany(data.company);
        setCurrentCompany(data.company);

        success(t("Zmieniono firmę"));
      })
      .catch(() => {
        error();
      });
  }

  useEffect(() => {
    getCompanies()
      .then((data) => {
        setCompanies(data.data[0].sort((a, b) => (a.name > b.name ? 1 : -1)));
      })
      .catch(() => {
        error();
      });
  }, []);

  return (
    <Box>
      {!companies && <Skeleton animation="wave" sx={{ width: "100%" }} />}
      {companies && companies?.length === 0 && (
        <Typography component="h3">{t("Brak firm do wyświetlenia")}</Typography>
      )}
      {companies && companies?.length !== 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Nazwa")}</TableCell>
                <TableCell>{t("Adres")}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies?.map((company: ICompany) => (
                <TableRow key={company.id} hover>
                  <TableCell>{company.name}</TableCell>
                  <TableCell>
                    {company.address} <br />
                    {company.postalCode} {company.city}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant={currentCompany?.id === company.id ? "contained" : "outlined"}
                      onClick={() => handleChangeToken(company.id)}
                    >
                      {currentCompany?.id === company.id ? t("Wybrano") : t("Wybierz")}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default IndexAdmin;
