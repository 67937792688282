import React, { Dispatch, SetStateAction } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import IUser from "../../../types/IUser";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { color } from "../../../themes/globals";
import { useTranslation } from "react-i18next";

export default function FormSettings({
  onSubmit,
  data,
  setData
}: Readonly<{
  onSubmit: (data: Partial<IUser>) => void;
  data: IUser;
  setData: Dispatch<SetStateAction<IUser>> | Dispatch<SetStateAction<IUser | null>>;
}>) {
  const { t } = useTranslation();

  function handleChange(event: React.FormEvent<any>) {
    setData({
      ...data,
      [event.currentTarget.name]: event.currentTarget.value
    });
  }

  function handleSubmit(a: React.FormEvent) {
    onSubmit({ name: data.name, phone: data.phone });
  }

  return (
    <Box>
      <ValidatorForm onSubmit={handleSubmit}>
        <Box sx={{ p: 3 }}>
          <Typography
            color={color.primary}
            sx={{
              textTransform: "uppercase",
              fontSize: ".8rem",
              fontWeight: "300"
            }}
          >
            {t("Dane personalne")}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(3, 1fr)"
              },
              gap: 1
            }}
            mb={0}
            mt={3}
          >
            <TextValidator
              label={t("Imię i nazwisko")}
              onChange={handleChange}
              name="name"
              value={data.name || ""}
              variant={"outlined"}
              validators={["required"]}
              errorMessages={[t("Pole jest wymagane")]}
              fullWidth
            />
          </Box>
        </Box>
        <Box sx={{ p: 3, pt: 0 }}>
          <Typography
            color={color.primary}
            sx={{
              textTransform: "uppercase",
              fontSize: ".8rem",
              fontWeight: "300"
            }}
          >
            {t("Dane kontaktowe")}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(3, 1fr)"
              },
              gap: 1
            }}
            mb={0}
            mt={3}
          >
            <TextValidator
              label={t("Adres e-mail")}
              name="email"
              value={data.email}
              variant="outlined"
              disabled
              fullWidth
            />
            <TextValidator
              label={t("Numer telefonu")}
              onChange={handleChange}
              name="phone"
              value={data.phone || ""}
              variant="outlined"
              validators={["matchRegexp:^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{5,6}$"]}
              helperText="Format +48111222333"
              errorMessages={[t("Niepoprawny format (np. 048111222333)")]}
              fullWidth
              sx={{
                marginTop: {
                  xs: "1rem",
                  sm: "0"
                }
              }}
            />
          </Box>
          <Grid>
            <Stack direction="row" mt={4} justifyContent="end">
              <Button variant="contained" type={"submit"} sx={{ width: { xs: "100%", sm: "25%" } }}>
                {t("Zapisz")}
              </Button>
            </Stack>
          </Grid>
        </Box>
      </ValidatorForm>
    </Box>
  );
}
