import axios, { AxiosPromise } from "axios";
import IAuth from "../types/IAuth";
import IAuthLoginResponse from "../types/IAuthLoginResponse";
import IAuthRefreshResponse from "../types/IAuthRefreshResponse";

export const authLogin = (auth: IAuth): AxiosPromise<IAuthLoginResponse> =>
  axios.post(`/auth/login`, auth);

export const authLogout = (): AxiosPromise<void> => axios.post(`/auth/logout`);

export const authRefresh = (
  email: string,
  companyId?: string | number
): AxiosPromise<IAuthRefreshResponse> =>
  axios.post(`/auth/refresh`, { email, company: companyId });

export const sendRemind = (email: IAuth): AxiosPromise<IAuth> =>
  axios.post(`/auth/remind/request`, email);

export const sendRemindPassword = (data: IAuth): AxiosPromise<IAuth> =>
  axios.post(`/auth/remind/change`, data);
