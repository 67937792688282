import { Grid } from "@mui/material";
import { ICardGroupWithItems } from "../../../types/ICardGroup";
import ICardGroupItem from "../../../types/ICardGroupItem";
import IReport from "../../../types/IReport";
import RideGroupElement from "./components/RideGroupElement";
import RideGroupItem from "./components/RideGroupItem";
import RideHeader from "./components/RideHeader";

const RideResult = ({ result }: { result: IReport }): JSX.Element => {
  const getGroupPoints = (group: ICardGroupWithItems, answers: Record<number, number>) => {
    const itemIds = group.items.reduce((acc, item) => {
      acc.push(item.id);
      return acc;
    }, [] as number[]);
    return itemIds.reduce((acc, id) => {
      return acc + answers[id];
    }, 0);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RideHeader report={result} />
      </Grid>
      {result.card.groups.map((group: ICardGroupWithItems, index: number) => (
        <Grid item xs={12} key={index}>
          <RideGroupElement
            group={group}
            index={index}
            points={result.result && getGroupPoints(group, result.answers)}
          >
            <>
              {group.items.map((item: ICardGroupItem, index: number) => (
                <RideGroupItem
                  reportDesc={result.report[item.id]}
                  comment={result.comments[item.id]}
                  item={item}
                  points={result.answers[item.id]}
                  key={index}
                />
              ))}
            </>
          </RideGroupElement>
        </Grid>
      ))}
    </Grid>
  );
};

export default RideResult;
