import {
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Stack
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useState } from "react";
import useAlerts from "../../hooks/useAlerts";
import { getReports } from "../../services/report.service";
import { Link } from "react-router-dom";
import { R_RIDE_FINISH, R_RIDE } from "../../config/routes";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { removeRide } from "../../services/card.service";

const TrainerReportsAll = (): JSX.Element => {
  const { error } = useAlerts();
  const MySwal = withReactContent(Swal);
  const { t } = useTranslation();

  const [value, setValue] = useState("1");
  const [reports, setReports] = useState<any>();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleRemove = (id: string) => {
    MySwal.fire({
      title: t("Czy jesteś pewien?"),
      text: t("Nie będziesz w stanie odzyskać danych!"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Tak, usuń!")
    }).then((result) => {
      if (result.isConfirmed === true) {
        removeRide(id);
        setReports(reports.filter((r: { id: string }) => r.id !== id));
      }
    });
  };

  useEffect(() => {
    getReports()
      .then((data) => {
        setReports(data.data);
      })
      .catch(() => {
        error();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="lab API tabs example" variant="fullWidth">
          <Tab label={t("Otwarte")} value="1" />
          <Tab label={t("Zamknięte")} value="2" />
        </TabList>
        <TabPanel value="1" sx={{ p: 0 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t("Imię i nazwisko")}</TableCell>
                <TableCell align="left">{t("Rozpoczęto")}</TableCell>
                <TableCell align="left">{t("Firma")}</TableCell>
                <TableCell align="left">{t("Karta")}</TableCell>
                <TableCell align="right">{t("Akcje")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports
                ?.filter((report: any) => report.endedAt === null)
                .sort((a: any, b: any) => (a.id < b.id ? 1 : -1))
                .map((row: any) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {row?.user.name} <br />
                      {row?.user.email}
                    </TableCell>
                    <TableCell align="left">
                      {new Date(row?.createdAt).toLocaleDateString(["pl-PL"])}{" "}
                      {new Date(row?.createdAt).toLocaleTimeString(["pl-PL"], {
                        hour: "2-digit",
                        minute: "2-digit"
                      })}
                    </TableCell>
                    <TableCell align="left">{row?.user.company.name}</TableCell>
                    <TableCell title={row?.card.name}>
                      {row?.card.name.length > 24
                        ? `${row?.card.name.slice(0, 21)}...`
                        : row?.card.name}{" "}
                      <br />
                    </TableCell>
                    <TableCell align="right">
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={1}
                      >
                        <Button color="error" onClick={() => handleRemove(row.id)}>
                          {t("Usuń")}
                        </Button>
                        <Button size="small" component={Link} to={R_RIDE(row.id)}>
                          {t("Szczegóły")}
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t("Imię i nazwisko")}</TableCell>
                <TableCell>{t("Data")}</TableCell>
                <TableCell align="left">{t("Firma")}</TableCell>
                <TableCell align="left">{t("Karta")}</TableCell>
                <TableCell align="right">{t("Akcje")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports
                ?.filter((report: any) => report.endedAt !== null)
                .sort((a: any, b: any) => (a.id < b.id ? 1 : -1))
                .map((row: any) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {row?.user.name} <br />
                      {row?.user.email}
                    </TableCell>
                    <TableCell>
                      {new Date(row?.endedAt).toLocaleDateString("pl-PL")}{" "}
                      {new Date(row?.endedAt).toLocaleTimeString(["pl-PL"], {
                        hour: "2-digit",
                        minute: "2-digit"
                      })}
                    </TableCell>
                    <TableCell align="left">{row?.user.company.name}</TableCell>
                    <TableCell title={row?.card.name}>
                      {row?.card.name.length > 24
                        ? `${row?.card.name.slice(0, 21)}...`
                        : row?.card.name}
                    </TableCell>
                    <TableCell align="right">
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={1}
                      >
                        <Button color="error" onClick={() => handleRemove(row.id)}>
                          {t("Usuń")}
                        </Button>
                        <Button size="small" component={Link} to={R_RIDE_FINISH(row.id)}>
                          {t("Szczegóły")}
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TabPanel>
      </TabContext>
    </Paper>
  );
};
export default TrainerReportsAll;
