import BreadcrumbsContainer from "@mui/material/Breadcrumbs";
import React from "react";
import useReactRouterBreadcrumbs, { BreadcrumbData } from "use-react-router-breadcrumbs";
import { routesAdmin, routesCompanyOwner, routesTrainer, routes } from "../config/routes";
import { getTokenUser } from "../utils/tokenUtils";
import { useTranslation } from "react-i18next";
import IUser from "../types/IUser";
import { Link } from "react-router-dom";

export default function Breadcrumbs(): JSX.Element {
  const { t } = useTranslation();

  const getRoutes = (user: IUser | null) => {
    switch (user?.role) {
      case "User":
        return routes;

      case "Company":
        return routesCompanyOwner;

      case "Trainer":
        return routesTrainer;

      case "Admin":
        return routesAdmin;

      default:
        return routes;
    }
  };

  const finalRoutes = getRoutes(getTokenUser()).map((route) => {
    route.breadcrumb = t(route.breadcrumb);
    return route;
  });

  const allBreadbrumbs = useReactRouterBreadcrumbs(finalRoutes, {
    defaultFormatter: (str) => {
      return t(str);
    }
  });

  // const lastBreadcrumb: BreadcrumbData = allBreadbrumbs[allBreadbrumbs.length - 1];

  return (
    <BreadcrumbsContainer aria-label="breadcrumb">
      {allBreadbrumbs.map(
        (breadcrumb) =>
          /[A-Z]/.test(
            (breadcrumb.breadcrumb as BreadcrumbData & { props: { children: string } }).props
              .children[0]
          ) && (
            <Link to={breadcrumb.key} key={breadcrumb.key} style={{ textDecoration: "none" }}>
              {breadcrumb.breadcrumb}
            </Link>
          )
      )}
    </BreadcrumbsContainer>
  );
}
