import axios, { AxiosPromise } from "axios";
import IUser, { IPasswordRecovery } from "../types/IUser";

export const changePassword = (passwords: IPasswordRecovery): AxiosPromise<IPasswordRecovery> =>
  axios.post(`/auth/change-password/`, passwords);

export const updateUser = (data: Partial<IUser>): AxiosPromise<IUser> =>
  axios.post(`/auth/profile/`, data);

export const getUser = (id: string | number): AxiosPromise<IUser> => axios.get(`/users/${id}`);
