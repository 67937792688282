import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrayLoadingSkeleton from "../../components/ArrayLoadingSkeleton";
import StatsResultItem from "../common/components/StatsResultItem";
import { R_TEST_FINISH } from "../../config/routes";
import ITestWithResults from "../../types/ITestWithResults";

export default function UserTestResultsList({
  results
}: {
  results: ITestWithResults[] | undefined;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <ArrayLoadingSkeleton emptyText={t("Brak wyników do wyświetlenia")} data={results}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t("Egzamin")}</TableCell>
              <TableCell align="center">{t("Progres")}</TableCell>
              <TableCell align="center">{t("Prób")}</TableCell>
              <TableCell align="right">{t("Wyniki")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results?.map((result: ITestWithResults) => (
              <StatsResultItem key={result.id} data={result} linkTo={R_TEST_FINISH} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ArrayLoadingSkeleton>
  );
}
