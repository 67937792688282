import React, { Dispatch, SetStateAction } from "react";
import { Button, Grid, Typography, Stack } from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import IAuth from "../../../types/IAuth";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

export default function PasswordForm({
  onSubmit,
  data,
  setData
}: {
  onSubmit: (data: IAuth) => void;
  data: IAuth;
  setData: Dispatch<SetStateAction<IAuth>> | Dispatch<SetStateAction<IAuth | undefined>>;
}) {
  const { t } = useTranslation();

  function handleChange(event: React.FormEvent<any>) {
    setData({
      ...data,
      [event.currentTarget.name]: event.currentTarget.value
    });
  }

  function handleSubmit(a: React.FormEvent) {
    delete data.repeatPassword;

    onSubmit({ ...data });
  }

  ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
    if (value !== data?.password) {
      return false;
    }
    return true;
  });

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <Grid>
        <Typography variant="h5" component="h1" sx={{ mb: 2 }}>
          {t("Utwórz nowe hasło")}
        </Typography>
      </Grid>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)"
          },
          gap: 3
        }}
        pb={4}
        pt={3}
      >
        <TextValidator
          label={t("Nowe hasło")}
          name="password"
          onChange={handleChange}
          value={data?.password || ""}
          variant="outlined"
          validators={["required"]}
          errorMessages={[t("Pole jest wymagane")]}
          type="password"
          fullWidth
        />
        <TextValidator
          label={t("Powtórz hasło")}
          name="repeatPassword"
          onChange={handleChange}
          value={data?.repeatPassword || ""}
          variant="outlined"
          validators={["isPasswordMatch", "required"]}
          errorMessages={[t("Hasła nie są takie same"), t("Pole jest wymagane")]}
          type="password"
          fullWidth
        />
      </Box>

      <Grid>
        <Stack direction="row" pt={3} justifyContent="end">
          <Button variant="outlined" type={"submit"} sx={{ width: { xs: "100%", sm: "25%" } }}>
            {t("Zapisz")}
          </Button>
        </Stack>
      </Grid>
    </ValidatorForm>
  );
}
