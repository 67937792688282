import { MenuItem, Select } from "@mui/material";
import { getBrowserLocale, getLanguage, setLanguage } from "../utils/languagePicker";
import { useState } from "react";

const LanguagePicker = () => {
  const [lang, setLang] = useState<string>(getLanguage() || getBrowserLocale());

  return (
    <Select
      value={lang}
      size={"small"}
      // color={"secondary"}
      // variant={"standard"}
      onChange={(e) => {
        setLanguage(e.target.value);
        setLang(e.target.value);
      }}
    >
      <MenuItem value={"pl"}>PL</MenuItem>
      <MenuItem value={"en"}>EN</MenuItem>
      <MenuItem value={"de"}>DE</MenuItem>
    </Select>
  );
};

export default LanguagePicker;
