import ArrayLoadingSkeleton from "../../../components/ArrayLoadingSkeleton";
import { useTranslation } from "react-i18next";
import ITestResult from "../../../types/ITestResult";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material";
import IReport from "../../../types/IReport";

export default function TestResultsLineChart({
  data
}: Readonly<{ data: ITestResult[] | IReport[] | undefined }>) {
  const { t } = useTranslation();
  const theme = useTheme();

  const results = data?.sort(
    (a: ITestResult | IReport, b: ITestResult | IReport) =>
      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );

  return (
    <ArrayLoadingSkeleton emptyText={t("Brak ewaluacji do wyświetlenia")} data={data}>
      <ReactApexChart
        // style={{ marginBottom: -2 }}
        height={64}
        width={"100%"}
        type="line"
        series={[
          {
            name: t("Wynik"),
            data: results?.map(({ result }) => result) ?? []
          }
        ]}
        options={{
          chart: {
            // type: "area",
            // width: "100%",
            offsetX: 0,
            offsetY: 0,
            parentHeightOffset: -10,
            zoom: { enabled: false },
            animations: { enabled: false },
            toolbar: { show: false },
            sparkline: { enabled: true }
          },
          colors: [theme.palette.secondary.light],
          stroke: { show: true, width: 2 },
          grid: {
            show: false,
            padding: { left: 0, bottom: 0, right: 0, top: 0 },
            xaxis: { lines: { show: false } },
            yaxis: { lines: { show: false } }
          },
          xaxis: {
            tickAmount: undefined,
            labels: { show: false },
            axisBorder: { show: false },
            axisTicks: { show: false },
            crosshairs: { show: false },
            tooltip: { enabled: false }
          },
          yaxis: {
            max: 100,
            min: 0,
            show: false,
            showAlways: false,
            opposite: false,
            reversed: false,
            logarithmic: false,
            tickAmount: undefined,
            labels: { show: false },
            axisBorder: { show: false },
            axisTicks: { show: false },
            crosshairs: { show: false },
            tooltip: { enabled: false }
          },
          tooltip: {
            enabled: true,
            shared: false,
            x: { show: false },
            marker: { show: false },
            followCursor: false
          }
        }}
      />
    </ArrayLoadingSkeleton>
  );
}
