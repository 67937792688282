import {
  Button,
  Link,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import ArrayLoadingSkeleton from "../../../components/ArrayLoadingSkeleton";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ITest from "../../../types/ITest";
import ITestResult from "../../../types/ITestResult";
import TestResultsLineChart from "./TestResultsLineChart";
import { getFormattedDate } from "../../../utils/dateUtils";
import { getStatsColorByResult } from "../../../utils/statsUtils";

export default function CompanyUserTestResultsList({
  data
}: Readonly<{ data: (ITest & { results: ITestResult[] })[] | undefined }>) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [menuResults, setMenuResults] = useState<ITestResult[]>([]);

  //TODO: filter that tests are finished
  const sortedTests = data?.map((test) => {
    test.results = test.results.sort((a: ITestResult, b: ITestResult) => a.result - b.result);
    return test;
  });

  const handleResultCount = (results: ITestResult[]) => (event: React.MouseEvent<HTMLElement>) => {
    setMenuResults(results);
    setAnchorEl(event.currentTarget);
  };

  const getHighest = (test: ITest & { results: ITestResult[] }): ITestResult =>
    test.results[test.results.length - 1];
  const getLowest = (test: ITest & { results: ITestResult[] }): ITestResult => test.results[0];
  const getAvg = (results: ITestResult[]): number =>
    results.reduce((acc, { result }) => acc + result, 0) / results.length;

  return (
    <ArrayLoadingSkeleton emptyText="Brak uczestników do wyświetlenia" data={sortedTests}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("Nazwa")}</TableCell>
              <TableCell align="center">{t("Progres")}</TableCell>
              <TableCell align="center">{t("Prób")}</TableCell>
              <TableCell align="right">{t("Wyniki")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedTests
              //   ?.filter((user) => user.name?.toLowerCase().includes(searchValue.toLowerCase()))
              ?.map((test) => (
                <TableRow
                  key={test.id}
                  hover
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 }
                  }}
                >
                  <TableCell>
                    <Typography variant={"body1"}>{test.name}</Typography>
                  </TableCell>
                  <TableCell align="center" sx={{ overflow: "hidden", maxWidth: 187 }}>
                    <TestResultsLineChart data={test.results} />
                  </TableCell>
                  <TableCell align="center">
                    <Button onClick={handleResultCount(test.results)}>{test.results.length}</Button>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="h6">
                      {t("Średnia")} -{" "}
                      <strong style={{ color: getStatsColorByResult(getAvg(test.results ?? 0)) }}>
                        {Math.round(getAvg(test.results ?? 0))}%
                      </strong>
                    </Typography>
                    <Tooltip
                      describeChild
                      title={`${getFormattedDate(getHighest(test).createdAt ?? undefined).date} ${
                        getFormattedDate(getHighest(test).createdAt ?? undefined).time
                      }`}
                      arrow
                      placement="right"
                    >
                      <Link color={"secondary"}>
                        <Typography variant="body2">
                          {t("Najwyższy wynik")} -{" "}
                          <strong
                            style={{ color: getStatsColorByResult(getHighest(test).result ?? 0) }}
                          >
                            {getHighest(test).result}%
                          </strong>
                        </Typography>
                      </Link>
                    </Tooltip>
                    <Tooltip
                      title={`${getFormattedDate(getLowest(test).createdAt ?? undefined).date} ${
                        getFormattedDate(getLowest(test).createdAt ?? undefined).time
                      }`}
                      arrow
                      placement="right"
                    >
                      <Link color={"secondary"}>
                        <Typography variant="body2">
                          {t("Najniższy wynik")} -{" "}
                          <strong
                            style={{ color: getStatsColorByResult(getLowest(test).result ?? 0) }}
                          >
                            {getLowest(test).result}%
                          </strong>
                        </Typography>
                      </Link>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Menu open={!!anchorEl} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
          {menuResults.map((result) => (
            <MenuItem key={result.id}>
              {getFormattedDate(result.endedAt).date} {getFormattedDate(result.endedAt).time}
            </MenuItem>
          ))}
        </Menu>
      </TableContainer>
    </ArrayLoadingSkeleton>
  );
}
