import {
  Button,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PaperStepper from "../../../components/PaperStepper";
import { R_COMPANY_RIDES_CARD, R_INDEX_ADMIN } from "../../../config/routes";
import { getCompanyCards } from "../../../services/company.service";
import ICard from "../../../types/ICard";
import { getCompany, getTokenUser } from "../../../utils/tokenUtils";
import { useTranslation } from "react-i18next";
import useAlerts from "../../../hooks/useAlerts";

const CompanyRidesList = (): JSX.Element => {
  const { t } = useTranslation();
  const { error } = useAlerts();
  const navigate = useNavigate();

  const [cards, setCards] = useState<ICard[]>();

  useEffect(() => {
    const currentUser = getTokenUser();
    const currentCompany = getCompany();

    if (!currentCompany?.id && currentUser?.role === "Admin") {
      error(t("Wybierz firmę"));
      navigate(R_INDEX_ADMIN);
      return;
    }

    getCompanyCards().then(({ data }) => {
      setCards(
        data.sort((a: ICard, b: ICard) => {
          if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return -1;
          }
          if (b.name.toUpperCase() > a.name.toUpperCase()) {
            return 1;
          }
          return 0;
        })
      );
    });
  }, []);

  return (
    <Box>
      <PaperStepper steps={[t("Wybierz kartę"), t("Statystyki karty")]} step={0} />

      <Typography mt={2} mb={2} component="h2" variant="h6">
        {t("Karty")}
      </Typography>

      <Paper>
        {cards == null && <LinearProgress />}
        {cards?.length === 0 && (
          <Typography component="h3">
            <Box p={2}>{t("Brak kart do wyświetlenia")}</Box>
          </Typography>
        )}
        {cards && cards?.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Nazwa karty")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cards?.map((card, index) => (
                <TableRow hover key={index}>
                  <TableCell>{card.name}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      onClick={() => navigate(R_COMPANY_RIDES_CARD(card.id))}
                    >
                      {t("Wybierz")}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Paper>
    </Box>
  );
};

export default CompanyRidesList;
