import React, { Dispatch, SetStateAction } from "react";
import { Button, Typography, Box } from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import IAuth from "../../../types/IAuth";
import { Link } from "react-router-dom";
import { R_INDEX } from "../../../config/routes";
import { useTranslation } from "react-i18next";

export default function RemindForm({
  onSubmit,
  data,
  setData
}: {
  onSubmit: (data: IAuth) => void;
  data: IAuth;
  setData: Dispatch<SetStateAction<IAuth>> | Dispatch<SetStateAction<IAuth | undefined>>;
}) {
  const { t } = useTranslation();

  function handleChange(event: React.FormEvent<any>) {
    setData({
      ...data,
      [event.currentTarget.name]: event.currentTarget.value
    });
  }

  function handleSubmit(a: React.FormEvent) {
    onSubmit({ ...data });
  }

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <Box sx={{ my: 4 }}>
        <Typography color="textPrimary" variant="h6">
          {t("Przypomnij hasło")}
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="body2">
          {t("Na podany adres e-mail wyślemy dalsze instrukcje")}
        </Typography>
      </Box>
      <TextValidator
        label={t("Adres e-mail")}
        onChange={handleChange}
        name="email"
        placeholder={t("Wpisz adres e-mail")}
        variant="outlined"
        value={data.email || ""}
        validators={["required", "isEmail"]}
        errorMessages={[t("Pole jest wymagane"), t("Adres e-mail jest niepoprawny")]}
        fullWidth
      />
      <Box sx={{ py: 2, mt: 1 }}>
        <Button color="primary" fullWidth size="large" type="submit" variant="contained">
          {t("Wyślij")}
        </Button>
      </Box>
      <Typography color="textSecondary" variant="body2" align="center">
        <Link
          to={R_INDEX}
          style={{
            cursor: "pointer",
            textDecoration: "none"
          }}
        >
          {t("Logowanie")}
        </Link>
      </Typography>
    </ValidatorForm>
  );
}
