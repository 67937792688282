import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  Typography
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useParams } from "react-router-dom";
import { R_INDEX } from "../../config/routes";
import { getFinishTest, getTest } from "../../services/test.service";
import useAlerts from "../../hooks/useAlerts";
import Confetti from "react-confetti";
import useWindowSize from "../../hooks/windowSize";
import { getLanguage } from "../../utils/languagePicker";
import MediaDisplay from "../../components/MediaDisplay";
import { useTranslation } from "react-i18next";

const TestFinish = (): JSX.Element => {
  const { t } = useTranslation();
  const { error } = useAlerts();
  const { id = 0 } = useParams();

  const [test, setTest] = useState<any>();
  const [goodAnswers, setGoodAnswers] = useState<any>();
  const [userAnswers, setUserAnswers] = useState<any>();
  const [questions, setQuestions] = useState<any>();
  const [showConfetti, setShowConfetti] = useState<any>(false);
  const [whenSucceed, setWhenSucceeed] = useState<number>(70);
  const windowSize = useWindowSize();

  const checkAnswerToCheckbox = (qID: number, aKey: string) => {
    if (goodAnswers.length !== 0 && goodAnswers[qID]) {
      return goodAnswers[qID].some((element: string) => {
        return element === aKey;
      });
    }
  };

  const checkAnswerClassName = (qID: number, aKey: string) => {
    if (userAnswers.length !== 0 && userAnswers[qID]) {
      return userAnswers[qID].some((element: string) => {
        return element === aKey;
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    getFinishTest(id)
      .then((data) => {
        if (isMounted) {
          setTest(data.data);

          setGoodAnswers(data.data.questions);
          setUserAnswers(data.data.answers);

          setWhenSucceeed(data.data.test.whenSucceed);

          if (data.data.result > data.data.test.whenSucceed) {
            setShowConfetti(true);

            setInterval(() => {
              setShowConfetti(false);
            }, 5000);
          }

          if (data) {
            getTest(data.data.test.id)
              .then((data) => {
                data.data.questions?.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));

                setQuestions(data.data.questions);
              })
              .catch((err) => {
                console.error(err);
                error(t("Ups, coś poszło nie tak"));
              });
          }
        }
      })
      .catch((err) => {
        console.error(err);
        error(t("Ups, coś poszło nie tak"));
      });
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box className="test_view">
        <Link to={R_INDEX} className="back_button">
          <ArrowBackIcon /> {t("Strona Główna")}
        </Link>
        {test && (
          <>
            {showConfetti && <Confetti width={windowSize.width} height={windowSize.height} />}
            <Paper sx={{ p: { xs: 2, sm: 3 }, mt: 1, mb: 4 }} className="test_list">
              {test?.endedAt && test?.result > whenSucceed && (
                <Typography variant="h6" sx={{ fontWeight: 300 }}>
                  {t("Twój wynik")}
                  {t("to")} <strong>{test?.result}%</strong>, {t("gratulujemy!")}
                </Typography>
              )}
              {test?.endedAt && (test?.result < whenSucceed || test?.result === whenSucceed) && (
                <Typography variant="h6" sx={{ fontWeight: 300 }}>
                  {t("Twój wynik")}
                  {t("to")} <strong>{test?.result}%</strong>,{" "}
                  {t("sprawdź i przeanalizuj odpowiedzi.")}
                </Typography>
              )}
              {!test?.endedAt && (
                <Typography variant="h6" sx={{ fontWeight: 300 }}>
                  {t("Twój wynik")}
                  {t("to")} <strong>{test?.result}%</strong>,{" "}
                  {t("jednak egzamin nie został wypełniony do końca!")}
                </Typography>
              )}
            </Paper>
          </>
        )}
        {test?.endedAt &&
          questions &&
          questions?.length !== 0 &&
          questions?.map((question: any, index: number) => {
            return (
              <Box sx={{ flexGrow: 1 }} key={question.id}>
                <Paper
                  square
                  sx={{
                    p: { xs: 2, sm: 3 },
                    mt: 1,
                    mb: 4,
                    borderRadius: "8px"
                  }}
                >
                  <>
                    <Box sx={{ mb: 3, display: "flex", justifyContent: "end" }}>
                      <Typography>
                        {t("Pytanie")} {index + 1}/{questions?.length}
                      </Typography>
                    </Box>
                    <MediaDisplay media={question.media} />
                    <Box sx={{ pt: 2, textAlign: "center" }}>
                      <Typography>{question.question[getLanguage()]}</Typography>
                    </Box>
                    <Box sx={{ pt: 2 }}>
                      <List sx={{ width: "100%" }} className="answers_list">
                        {Object.keys(question.answers).map((key) => {
                          if (question.answers[key][getLanguage()] === "") {
                            return null;
                          }

                          const labelId = `checkbox-list-label-${key}`;
                          const yourAnswer = checkAnswerClassName(question.id, key);
                          const goodAnswer = checkAnswerToCheckbox(question.id, key);

                          const className =
                            yourAnswer === true
                              ? goodAnswer === true
                                ? "good_answer"
                                : "bad_answer"
                              : "";

                          return (
                            <ListItem key={key} disablePadding className={className}>
                              <ListItemButton dense>
                                <ListItemIcon className="test_checkbox">
                                  <Checkbox
                                    edge="start"
                                    checked={goodAnswer}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                    disabled
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  primary={question.answers[key][getLanguage()]}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                  </>
                </Paper>
              </Box>
            );
          })}
        {!test && (
          <>
            <Paper sx={{ p: { xs: 2, sm: 3 }, mt: 1, mb: 4 }} className="test_list">
              <Skeleton animation="wave" sx={{ width: "100%" }} />
            </Paper>
          </>
        )}
      </Box>
    </Box>
  );
};

export default TestFinish;
