import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import ICardGroupItem from "../../../../types/ICardGroupItem";
import { getLanguage } from "../../../../utils/languagePicker";
import supportedLanguagesType from "../../../../types/supportedLanguagesType";
import CommentIcon from "@mui/icons-material/Comment";
import SummarizeIcon from "@mui/icons-material/Summarize";

const RideGroupItem = ({
  reportDesc,
  item,
  points,
  comment
}: {
  reportDesc?: string | Record<supportedLanguagesType, string>;
  comment?: string;
  item: ICardGroupItem;
  points: number;
}): JSX.Element => {
  const reportText =
    reportDesc && typeof reportDesc === "object" ? reportDesc[getLanguage()] : reportDesc;

  return (
    <Grid container spacing={2} p={2}>
      <Grid item md={10} xs={10}>
        <Typography variant="caption" sx={(theme) => ({ color: theme.palette.grey[600] })}>
          {item.name[getLanguage()]}
        </Typography>
      </Grid>
      <Grid item md={2} xs={2} display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Typography variant="h5">
          {points} / {item.points}
        </Typography>
      </Grid>
      <Grid item md={10} xs={12}>
        {reportText && (
          <Stack direction={"row"}>
            <SummarizeIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
            <Typography variant="body1" sx={{ overflowWrap: "anywhere" }}>
              {reportText}
            </Typography>
          </Stack>
        )}
        {comment && (
          <Stack direction={"row"}>
            <CommentIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
            <Typography variant="body1" sx={{ overflowWrap: "anywhere" }} maxWidth={"100%"}>
              {comment}
            </Typography>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default RideGroupItem;
