import { Box, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { R_INDEX } from "../../config/routes";
import useAlerts from "../../hooks/useAlerts";
import { clearSessionStorage } from "../../utils/tokenUtils";
import { useTranslation } from "react-i18next";
import { authLogout } from "../../services/auth.service";

export default function Logout(): JSX.Element {
  const { t } = useTranslation();
  const { error, info } = useAlerts();
  const navigate = useNavigate();

  useEffect(() => {
    authLogout()
      .then((res) => {
        clearSessionStorage();
        navigate(R_INDEX);
        info(t("Wylogowano poprawnie")); //TODO: fix, it shows 4 times
      })
      .catch((err) => {
        error(t("Nie udało się wylogować"));
      });
  });

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <CircularProgress />
    </Box>
  );
}
