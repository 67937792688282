import supportedLanguagesType from "../types/supportedLanguagesType";

export const setLanguage = (lang: string) => {
  localStorage.setItem("local_lang", lang);
  window.location.reload();
};
export const getLanguage = (): supportedLanguagesType =>
  (localStorage.getItem("local_lang") as supportedLanguagesType) ?? "pl";

export const getBrowserLocale = (options = {}) => {
  const opt = {
    languageCodeOnly: true,
    ...options
  };

  const locale = !navigator.languages.length ? navigator.language : navigator.languages[0];

  if (!locale) {
    return "pl";
  }

  const trimmedLocale = locale.trim();

  return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
};
