import {
  Button,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import ArrayLoadingSkeleton from "../../../components/ArrayLoadingSkeleton";
import { useTranslation } from "react-i18next";
import IUser from "../../../types/IUser";
import { useNavigate } from "react-router-dom";
import { R_COMPANY_USER } from "../../../config/routes";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";

export default function UsersList({ users }: Readonly<{ users: IUser[] | undefined }>) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>("");

  function handleSearch(input: string) {
    setSearchValue(input);
  }

  return (
    <ArrayLoadingSkeleton emptyText={t("Brak uczestników do wyświetlenia")} data={users}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("Imię i nazwisko")}</TableCell>
              <TableCell>{t("Adres e-mail")}</TableCell>
              <TableCell
                align="right"
                sx={{
                  p: 0
                }}
              >
                <TextField
                  label={t("Wyszukaj")}
                  variant="filled"
                  type="search"
                  size="small"
                  onChange={(e) => handleSearch(e.target.value)}
                  value={searchValue || ""}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      borderRadius: 0,
                      background: "transparent"
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              ?.filter((user) => user.name?.toLowerCase().includes(searchValue.toLowerCase()))
              ?.map((user: IUser) => (
                <TableRow
                  key={user.id}
                  hover
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 }
                  }}
                >
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={() => navigate(R_COMPANY_USER(user.id))}
                    >
                      {t("Szczegóły")}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ArrayLoadingSkeleton>
  );
}
