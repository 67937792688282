import React, { useState } from "react";
import IUser from "../../types/IUser";
import { Box, CircularProgress, Paper } from "@mui/material";
import AvatarSettings from "./components/AvatarSettings";
import FormSettings from "./components/FormSettings";
import PasswordSettings from "./components/PasswordSettings";
import useAlerts from "../../hooks/useAlerts";
import { updateUser } from "../../services/user.service";
import { getTokenUser, setTokenUser } from "../../utils/tokenUtils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Settings(): JSX.Element {
  const { t } = useTranslation();
  const { error } = useAlerts();
  const navigate = useNavigate();

  const [data, setData] = useState<IUser | null>(getTokenUser());

  function handleSubmit(data: Partial<IUser> | null) {
    if (data) {
      updateUser(data)
        .then(({ data }) => {
          setTokenUser(data);

          navigate(0);
        })
        .catch((err) => {
          console.error(err);
          error(t("Nie można zaktualizować danych"));
        });
    }
  }

  if (!data) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper>
      <AvatarSettings data={data} />
      <FormSettings data={data} setData={setData} onSubmit={handleSubmit} />
      <PasswordSettings data={data.id as number} />
    </Paper>
  );
}
