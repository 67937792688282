import React, { useEffect, useState } from "react";
import IUser from "../../types/IUser";
import { useNavigate } from "react-router-dom";
import { R_INDEX_ADMIN } from "../../config/routes";
import { getCompanyUsers } from "../../services/company.service";
import { getCompany, getTokenUser } from "../../utils/tokenUtils";
import useAlerts from "../../hooks/useAlerts";
import { useTranslation } from "react-i18next";
import UsersList from "./users/UsersList";
import { Grid } from "@mui/material";
import UsersCountOnTimeLine from "./users/UsersCountOnTimeLine";

const IndexCompany = (): JSX.Element => {
  const { t } = useTranslation();
  const { error } = useAlerts();
  const navigate = useNavigate();

  const [users, setUsers] = useState<IUser[]>();

  useEffect(() => {
    const currentUser = getTokenUser();
    const currentCompany = getCompany();

    if (!currentCompany?.id && currentUser?.role === "Admin") {
      error(t("Wybierz firmę"));
      navigate(R_INDEX_ADMIN);
      return;
    }

    getCompanyUsers()
      .then(({ data }) => {
        setUsers(
          data
            .filter((user: IUser) => user.role !== "Company")
            .sort((a, b) => (a.name > b.name ? 1 : -1))
        );
      })
      .catch(() => {
        error();
      });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        {/* <UsersCount users={users} /> */}
      </Grid>
      <Grid item xs={12}>
        <UsersCountOnTimeLine users={users} />
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={12}>
        <UsersList users={users} />
      </Grid>
    </Grid>
  );
};

export default IndexCompany;
