import axios, { AxiosPromise } from "axios";
import ICard from "../types/ICard";
import IUserWithCompany from "../types/IUserWithCompany";
import IReport from "../types/IReport";

export const getCards = (): AxiosPromise<[ICard[], number]> => axios.get(`/api/cards`);
export const getCardsUsers = (): AxiosPromise<IUserWithCompany[]> => axios.get(`/api/cards/users`);
export const startRide = (data: any): AxiosPromise => axios.post(`/api/cards/rides`, data);

export const finishRide = (id: number | string, answers: any, comments: any): AxiosPromise =>
  axios.put(`/api/cards/rides/${id}`, { answers: answers, comments: comments });

export const saveRide = (
  id: number | string,
  answers: Record<string, number>,
  comments: Record<string, string>
): AxiosPromise =>
  axios.patch(`/api/cards/rides/${id}`, {
    answers: answers,
    comments: comments
  });

export const getRide = (id: number | string): AxiosPromise<IReport> =>
  axios.get(`api/cards/rides/${id}`);

export const removeRide = (id: number | string): AxiosPromise =>
  axios.delete(`api/cards/rides/${id}`);
