import React from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, Paper, TextField } from "@mui/material";
import IUser from "../../../types/IUser";
import IUserWithCompany from "../../../types/IUserWithCompany";

const UserPicker = ({
  users,
  onPickUser
}: {
  users: IUserWithCompany[];
  onPickUser: (user: IUser | null) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Paper sx={{ p: 1 }}>
      <Autocomplete
        options={users
          .sort((a: IUserWithCompany, b: IUserWithCompany) =>
            a.name === b.name ? 0 : a.name > b.name ? 1 : -1
          )
          .sort((a: IUserWithCompany, b: IUserWithCompany) =>
            a.company.name > b.company.name ? 1 : -1
          )}
        groupBy={(user) => user.company.name}
        onChange={(event, newValue) => {
          if (typeof newValue === "object") {
            if (newValue) {
              const { company, ...user } = newValue;
              onPickUser({ ...user, company: company.id });
            } else {
              onPickUser(null);
            }
          }
        }}
        getOptionLabel={(users) => users.name}
        renderInput={(params) => <TextField {...params} label={t("Wybierz uczestnika")} />}
        noOptionsText={t("Nie ma takiego uczestnika")}
      />
    </Paper>
  );
};

export default UserPicker;
