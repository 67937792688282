import { Box, Paper, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ArrayLoadingSkeleton({
  emptyText,
  data,
  children
}: Readonly<{
  emptyText?: string;
  data: unknown[] | undefined;
  children: JSX.Element;
}>): JSX.Element {
  const { t } = useTranslation();

  if (!data) {
    return <Skeleton animation="wave" sx={{ width: "100%" }} />;
  }

  if (data && data.length === 0 && emptyText) {
    return emptyText ? (
      <Paper>
        <Box
          padding={2}
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <Typography gutterBottom={false} component="h3">
            {t(emptyText)}
          </Typography>
        </Box>
      </Paper>
    ) : (
      <></>
    );
  }

  return children;
}
