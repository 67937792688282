import { themePalette } from "../themes/palette";
import IReport from "../types/IReport";
import IStats from "../types/IStats";
import ITestResultWithTest from "../types/ITestResultWithTest";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";

export const groupReports = (reports: IReport[]) => {
  const groupedReports = Object.values(
    // Object.groupBy(data.results, (result: ITestResultWithUser) => result.user.id)
    reports.reduce((acc: { [key: string]: IReport[] }, report: IReport) => {
      if (report.endedAt) {
        acc[`${report.card.id}`] = acc[`${report.card.id}`] || [];
        acc[`${report.card.id}`].push(report);
      }

      return acc;
    }, {})
  );

  return groupedReports.map((values: IReport[]) => {
    return {
      ...values[0].card,
      ...getStatsFromResults<IReport>(values),
      results: values
    };
  });
};

export const groupResults = (results: ITestResultWithTest[]) => {
  const groupedResults = Object.values(
    // Object.groupBy(data.results, (result: ITestResultWithUser) => result.user.id)
    results.reduce((acc: { [key: string]: ITestResultWithTest[] }, result: ITestResultWithTest) => {
      acc[`${result.test.id}`] = acc[`${result.test.id}`] || [];
      acc[`${result.test.id}`].push(result);

      return acc;
    }, {})
  );

  return groupedResults.map((values: ITestResultWithTest[]) => {
    return {
      ...values[0].test,
      ...getStatsFromResults<ITestResultWithTest>(values),
      results: values
    };
  });
};

export const getStatsFromResults = <T>(results: (T & { result: number })[]): IStats<T> => {
  const data = results.sort((a, b) => +b.result - +a.result);
  const max = data[0]?.result ?? 0;
  const min = data[data.length - 1]?.result ?? 0;
  const attempts = data.length ?? 0;
  const avg = data.reduce((acc: number, { result }) => acc + result ?? 0, 0) / attempts;

  return {
    attempts,
    max,
    min,
    avg,
    highestResult: data[0],
    lowestResult: data[data.length - 1]
  };
};

export const getStatsColorByResult = (result: number) => {
  if (result >= 0 && result <= 35) {
    return themePalette.error.main;
  }

  if (result > 35 && result <= 80) {
    return themePalette.warning.main;
  }

  if (result > 80 && result <= 100) {
    return themePalette.success.main;
  }

  return themePalette.text.primary;
};

export const getStatsLightColorByResult = (result: number) => {
  if (result >= 0 && result <= 35) {
    return themePalette.error.light;
  }

  if (result > 35 && result <= 80) {
    return themePalette.warning.light;
  }

  if (result > 80 && result <= 100) {
    return themePalette.success.light;
  }

  return themePalette.text.primary;
};

export const getStatsSmileByResult = (result: number) => {
  if (result >= 0 && result <= 35) {
    return SentimentVeryDissatisfiedIcon;
  }

  if (result > 35 && result <= 80) {
    return SentimentSatisfiedIcon;
  }

  if (result > 80 && result <= 100) {
    return SentimentVerySatisfiedIcon;
  }

  return SentimentSatisfiedIcon;
};
