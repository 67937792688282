import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import React, { ChangeEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TrainerRideGroupItemComment = ({
  comment,
  onChange
}: {
  comment: string;
  onChange: (newComment: string) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>(comment);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    setValue(event.currentTarget.value);
    onChange(event.currentTarget.value);
  };

  useEffect(() => {
    setValue(comment);
  }, [comment]);

  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        {t("Komentarz")}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogTitle>{t("Komentarz")}</DialogTitle>
        <DialogContent>
          <TextField
            value={value}
            onChange={handleChange}
            rows={4}
            fullWidth
            multiline
            placeholder={t("Comment here...")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="warning">
            {t("Anuluj")}
          </Button>
          <Button onClick={handleClose} color="primary">
            {t("Zapisz")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TrainerRideGroupItemComment;
