import { createTheme } from "@mui/material";
import { themePalette } from "./palette";
import { themeTypography } from "./typography";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { LinkProps } from "@mui/material/Link";
import React, { forwardRef } from "react";
import { themeShadows } from "./shadows";

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>(function LinkBehavior(props, ref) {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1920
    }
  },
  palette: themePalette,
  typography: themeTypography,
  shadows: themeShadows,
  shape: {
    borderRadius: 8
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      } as LinkProps
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
          margin: 0,
          padding: 0
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%"
        },
        body: {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%"
        },
        "#__next": {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          height: "100%",
          width: "100%"
        }
      }
    },
    MuiButton: {
      defaultProps: {
        // disableElevation: true
      },
      styleOverrides: {
        root: {
          fontWeight: 500
        },
        sizeSmall: {
          padding: "4px 12px"
        },
        sizeMedium: {
          padding: "6px 16px"
        },
        sizeLarge: {
          padding: "11px 24px"
        },
        textSizeSmall: {
          padding: "7px 12px"
        },
        textSizeMedium: {
          padding: "9px 16px"
        },
        textSizeLarge: {
          padding: "12px 16px"
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "*": {
            fontSize: "inherit"
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          marginRight: "4px"
        },
        labelSmall: {
          fontSize: "0.85rem",
          lineHeight: "1"
        },
        iconSmall: {
          fontSize: "1rem"
        }
      }
    },
    //   MuiCardContent: {
    //     styleOverrides: {
    //       root: {
    //         padding: "32px 24px",
    //         "&:last-child": {
    //           paddingBottom: "32px"
    //         }
    //       }
    //     }
    //   },
    //   MuiCardHeader: {
    //     defaultProps: {
    //       titleTypographyProps: {
    //         variant: "h6"
    //       },
    //       subheaderTypographyProps: {
    //         variant: "body2"
    //       }
    //     },
    //     styleOverrides: {
    //       root: {
    //         padding: "32px 24px"
    //       }
    //     }
    //   },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: ({ theme }) => ({
          borderColor: theme.palette.grey[400]
        })
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: ({ theme }) => ({
          color: theme.palette.grey[800] //todo: think of implementing this to all form labels
        })
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.grey[700]
        })
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: ({ theme }) => ({
          // backgroundColor: theme.palette.grey[200], //todo change coloro to primary
          ".MuiTableCell-root": {
            color: theme.palette.common.black
          },
          // borderBottom: "none",
          "& .MuiFormLabel-root": {
            color: theme.palette.common.black,
            textTransform: "uppercase",
            fontWeight: 500,
            lineHeight: 1,
            letterSpacing: 0.5,
            fontSize: "0.75rem",
            top: 3
          },
          "& .MuiTableCell-root": {
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
            borderBottomColor: theme.palette.grey[200],
            borderTopColor: theme.palette.grey[200],
            fontWeight: 500,
            lineHeight: 1,
            letterSpacing: 0.5,
            textTransform: "uppercase"
          },
          "& .MuiTableCell-paddingCheckbox": {
            paddingTop: 4,
            paddingBottom: 4
          }
        })
      }
    },
    MuiAvatar: {
      defaultProps: {},
      styleOverrides: {}
    }
  }
});
