import { Avatar, Grid, Typography } from "@mui/material";
import { getFormattedDate } from "../../../utils/dateUtils";
import IEvent from "../../../types/IEvent";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";

const EventListItem = ({ event }: { event: IEvent }): JSX.Element => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={2} display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Avatar sx={{ bgcolor: event.color }}>
          <EventOutlinedIcon />
        </Avatar>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="caption" color="grey">
          {getFormattedDate(event.startTime).shortDate} {getFormattedDate(event.startTime).time}
          {" - "}
          {getFormattedDate(event.endTime).shortDate} {getFormattedDate(event.endTime).time}
        </Typography>
        <Typography variant="body1">{event.name}</Typography>
      </Grid>
    </Grid>
  );
};

export default EventListItem;
