import React from "react";
import { Box } from "@mui/system";
import { BACKEND_URL } from "../config/config";
import IImage from "../types/IImage";

export default function MediaDisplay({ media }: { media: IImage }) {
  if (!media || media == null || !media.url) return null;

  return media.type === "youtube" ? (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: 0,
        paddingBottom: "56.25%"
      }}
    >
      <iframe
        width={"100%"}
        height={"100%"}
        style={{ top: 0, left: 0, position: "absolute" }}
        src={`https://www.youtube.com/embed/${media.url}`}
        title={"YouTube video player"}
        frameBorder={"0"}
        allow={
          "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        }
      />
    </div>
  ) : (
    <Box sx={{ width: "100%" }}>
      <Box
        component="img"
        sx={{
          display: "block",
          overflow: "hidden",
          maxHeight: "80vh",
          height: "100%",
          width: "100%",
          margin: "1rem auto"
        }}
        src={media.url ? `${BACKEND_URL}files/open/${media.key}` : ""}
        alt="Zdjęcie do pytania"
      />
    </Box>
  );
}
