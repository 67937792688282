import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import ArrayLoadingSkeleton from "../../../components/ArrayLoadingSkeleton";
import { useTranslation } from "react-i18next";
import { getFormattedDate } from "../../../utils/dateUtils";
import { getStatsColorByResult } from "../../../utils/statsUtils";
import ITestWithResults from "../../../types/ITestWithResults";

export default function CompanyTestResultsStats({
  test
}: Readonly<{ test: ITestWithResults | undefined }>) {
  const { t } = useTranslation();

  return (
    <ArrayLoadingSkeleton data={[test]}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("Statystyki")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              hover
              sx={{
                "&:last-child td, &:last-child th": { border: 0 }
              }}
            >
              <TableCell>{t("Wyniki")}</TableCell>
              <TableCell align="right">
                <Typography variant="h6">
                  {t("Max")} -{" "}
                  <strong style={{ color: getStatsColorByResult(test?.max ?? 0) }}>
                    {test?.max ?? 0}%
                  </strong>
                </Typography>
                <Typography variant="h6">
                  {t("Średnia")} -{" "}
                  <strong style={{ color: getStatsColorByResult(test?.avg ?? 0) }}>
                    {Math.round(test?.avg ?? 0)}%{" "}
                  </strong>
                </Typography>
                <Typography variant="h6">
                  {t("Min")} -{" "}
                  <strong style={{ color: getStatsColorByResult(test?.min ?? 0) }}>
                    {test?.min ?? 0}%
                  </strong>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow
              hover
              sx={{
                "&:last-child td, &:last-child th": { border: 0 }
              }}
            >
              <TableCell>
                {t("Najwyższy wynik")} -{" "}
                <Typography variant="h6" sx={{ display: "inline-block" }}>
                  <strong
                    style={{ color: getStatsColorByResult(test?.highestResult?.result ?? 0) }}
                  >
                    {test?.highestResult?.result ?? 0}%
                  </strong>
                </Typography>
              </TableCell>
              {test?.highestResult ? (
                <TableCell align="right">
                  <Typography variant="h6">
                    <strong>{test?.highestResult?.user?.name ?? ""}</strong>
                  </Typography>
                  {getFormattedDate(test?.highestResult?.createdAt).date}{" "}
                  {getFormattedDate(test?.highestResult?.createdAt).time}
                </TableCell>
              ) : (
                <TableCell></TableCell>
              )}
            </TableRow>
            <TableRow
              hover
              sx={{
                "&:last-child td, &:last-child th": { border: 0 }
              }}
            >
              <TableCell>
                {t("Najniższy wynik")} -{" "}
                <Typography variant="h6" sx={{ display: "inline-block" }}>
                  <strong style={{ color: getStatsColorByResult(test?.lowestResult?.result ?? 0) }}>
                    {test?.lowestResult?.result ?? 0}%
                  </strong>
                </Typography>
              </TableCell>
              {test?.lowestResult ? (
                <TableCell align="right">
                  <Typography variant="h6">
                    <strong>{test?.lowestResult?.user?.name ?? ""}</strong> <br />
                  </Typography>
                  {getFormattedDate(test?.lowestResult?.createdAt).date}{" "}
                  {getFormattedDate(test?.lowestResult?.createdAt).time}
                </TableCell>
              ) : (
                <TableCell></TableCell>
              )}
            </TableRow>
            <TableRow
              hover
              sx={{
                "&:last-child td, &:last-child th": { border: 0 }
              }}
            >
              <TableCell>{t("Ilość uczestników")}</TableCell>
              <TableCell align="right">{test?.usersCount}</TableCell>
            </TableRow>
            <TableRow
              hover
              sx={{
                "&:last-child td, &:last-child th": { border: 0 }
              }}
            >
              <TableCell>{t("Wszystkich podejść do egzaminu")}</TableCell>
              <TableCell align="right">{test?.attempts}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </ArrayLoadingSkeleton>
  );
}
