import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MobileStepper,
  Paper,
  Skeleton,
  Typography
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { R_TEST_FINISH } from "../../config/routes";
import { answerTest, startTest } from "../../services/test.service";
import useAlerts from "../../hooks/useAlerts";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { getLanguage } from "../../utils/languagePicker";
import MediaDisplay from "../../components/MediaDisplay";

const TestQuestions = (): JSX.Element => {
  const { t } = useTranslation();
  const { error, info } = useAlerts();
  const navigate = useNavigate();

  const [test, setTest] = useState<any>({});
  const [countDown, setCountDown] = useState<number>(60);
  const [checked, setChecked] = useState<any>([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [nextQ, setNextQ] = useState<boolean>(false);
  const [loading, setLoader] = useState<boolean>(false);

  const { id = 0 } = useParams();

  const seconds = String(countDown % 60).padStart(2, "0");
  const minutes = String(Math.floor(countDown / 60)).padStart(2, "0");

  const handleNext = () => {
    const targetStep = activeStep + 1;

    if (countDown > 0) {
      setLoader(true);

      answerTest({ ["" + test.test.questions[activeStep].id]: checked }, test.id)
        .then((data) => {
          if (data.status === 201) {
            if (targetStep !== test.test.questions.length) {
              setActiveStep(targetStep);
              setNextQ(false);
              setChecked([]);
              setLoader(false);
            } else {
              handleRedirect();
            }
          }
        })
        .catch((err) => {
          console.error(err);

          if (err.response.status === 406) {
            navigate(R_TEST_FINISH());
            info(t("Czas egzaminu został przekroczony"));
          } else {
            error(t("Nie udało się wysłać odpowiedzi"));
            info(t("Spróbuj ponownie"));
          }
        });
    } else {
      navigate(R_TEST_FINISH());
      info(t("Czas egzaminu został przekroczony"));
    }
  };

  const handleRedirect = () => {
    setCountDown(0);
    navigate(R_TEST_FINISH(test.id));
  };

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setNextQ(true);
  };

  useEffect(() => {
    let isMounted = false;

    startTest(id)
      .then((data) => {
        if (isMounted) return;

        data.data.test.questions.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));

        setTest(data.data);
        setCountDown(data.data.test.timeOut * 60);
      })
      .catch((err) => {
        console.error(err);
        error(t("Ups, coś poszło nie tak"));
      });

    return () => {
      isMounted = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countDown > 0) {
      const timer = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          p: { xs: 2, sm: 3 },
          mt: 1,
          mb: 4,
          borderRadius: "8px"
        }}
      >
        {Object.keys(test).length !== 0 && (
          <>
            <Box sx={{ mb: 3, display: "flex", justifyContent: "space-between" }}>
              <Typography>
                {t("Pozostało")} {minutes}:{seconds} min
              </Typography>
              <Typography>
                {t("Pytanie")} {activeStep + 1}/{test.test.questions.length}
              </Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
              <MediaDisplay media={test.test.questions[activeStep]?.media} />
            </Box>
            <Box sx={{ pt: 2, textAlign: "center" }}>
              <Typography>{test.test.questions[activeStep]?.question[getLanguage()]}</Typography>
            </Box>
            <Box sx={{ pt: 2 }}>
              <List sx={{ width: "100%" }}>
                {test &&
                  Object.keys(test.test.questions[activeStep].answers).map((key) => {
                    if (test.test.questions[activeStep].answers[key].pl === "") {
                      return null;
                    }

                    const labelId = `checkbox-list-label-${key}`;

                    return (
                      <ListItem key={key} disablePadding>
                        <ListItemButton
                          role={undefined}
                          onClick={handleToggle("" + +key)}
                          dense
                          sx={{ paddingLeft: 0 }}
                        >
                          <ListItemIcon className="test_checkbox">
                            <Checkbox
                              edge="start"
                              checked={checked.indexOf("" + +key) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={test.test.questions[activeStep].answers[key][getLanguage()]}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </List>
            </Box>
            <MobileStepper
              variant="progress"
              steps={test.test.questions.length}
              position="static"
              activeStep={activeStep}
              className="question_stepper"
              sx={{ pl: 2, pr: 2, backgroundColor: "#fff" }}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={!nextQ}
                  sx={{ alignSelf: "flex-end" }}
                >
                  {activeStep === test.test.questions.length - 1
                    ? t("Zakończ")
                    : t("Następne pytanie")}
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={<></>}
            />
          </>
        )}
        {Object.keys(test).length === 0 && <Skeleton animation="wave" sx={{ width: "100%" }} />}
      </Paper>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default TestQuestions;
