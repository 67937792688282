import { Box, Paper, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { R_INDEX } from "../../config/routes";

import { getRide } from "../../services/card.service";
import useAlerts from "../../hooks/useAlerts";
import RideResult from "../common/ride/RideResult";
import IReport from "../../types/IReport";

const TrainerRideSummary = (): JSX.Element => {
  const { id = 0 } = useParams();
  const navigate = useNavigate();

  const { error, warning } = useAlerts();

  const [ride, setRide] = useState<IReport>();

  useEffect(() => {
    if (id) {
      getRide(id)
        .then((data) => {
          if (data.data.endedAt) {
            data.data.card.groups.sort((a, b) => (a.index > b.index ? 1 : -1));

            setRide(data.data);
          } else {
            warning("Ewaluacja trwa");
            navigate(R_INDEX);
          }
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            error();
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box py={2}>
      {!ride && (
        <Paper>
          <Skeleton animation="wave" sx={{ width: "100%" }} />
        </Paper>
      )}
      {ride && <RideResult result={ride} />}
    </Box>
  );
};

export default TrainerRideSummary;
