import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrayLoadingSkeleton from "../../../components/ArrayLoadingSkeleton";
import ICardWithResults from "../../../types/ICardWithResults";
import StatsResultItem from "../components/StatsResultItem";
import { R_RIDE_FINISH } from "../../../config/routes";

export default function UserReportsList({
  reports
}: {
  reports: ICardWithResults[] | undefined;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <ArrayLoadingSkeleton emptyText={t("Brak raportów do wyświetlenia")} data={reports}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t("Karta")}</TableCell>
              <TableCell align="center">{t("Progres")}</TableCell>
              <TableCell align="center">{t("Prób")}</TableCell>
              <TableCell align="right">{t("Wyniki")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports?.map((report: ICardWithResults) => (
              <StatsResultItem key={report.id} data={report} linkTo={R_RIDE_FINISH} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ArrayLoadingSkeleton>
  );
}
