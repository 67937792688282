import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAlerts from "../../hooks/useAlerts";
import {
  getCompanyUserRides,
  getCompanyUsers,
  getCompanyUserTestsResults
} from "../../services/company.service";

import IUser from "../../types/IUser";
import IReport from "../../types/IReport";
import ITest from "../../types/ITest";
import { useTranslation } from "react-i18next";
import UserReportsList from "./user/UserReportsList";
import ITestResult from "../../types/ITestResult";
import CompanyUserTestResultsList from "./user/CompanyUserTestResultsList";

const CompanyUser = (): JSX.Element => {
  const { t } = useTranslation();
  const { error } = useAlerts();
  const { id = 0 } = useParams();

  const [testsResults, setTestsResults] = useState<(ITest & { results: ITestResult[] })[]>();
  const [rides, setRides] = useState<IReport[]>();
  const [user, setUser] = useState<IUser>();

  /**
   * 1 Step in process
   * Get user in current view based on ID in url
   */
  useEffect(() => {
    getCompanyUsers()
      .then(({ data }) => {
        setUser(data.find((user) => user.role !== "Company" && user.id === +id));
      })
      .catch(() => {
        error();
      });
  }, [id]);

  /**
   * Step 2
   * When we have user get rest of the details
   */
  useEffect(() => {
    if (user) {
      getCompanyUserRides("" + user.id)
        .then(({ data }) => {
          setRides(
            data
              // .filter((report) => report.endedAt !== null) //TODO odblokowano pokazywanie niezakonczonych jazd
              .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          );
        })
        .catch((err) => {
          if (err.response.status !== 401) { 
            error();
          }
        });

      // getCompanyUserTests("" + user.id)
      //   .then(({ data }) => {
      //     setTests(
      //       data.filter((test) => test.timeOut > 0).sort((a, b) => (a.name > b.name ? 1 : -1))
      //     );
      //   })
      //   .catch((err) => {
      //     if (err.response.status !== 401) {
      //       error();
      //     }
      //   });
      // TODO: what to do with this service it's curenlty not used, but mayby we need some logic to show here

      getCompanyUserTestsResults("" + user.id).then(({ data }) => {
        const reduced = data.reduce(
          (acc, { test, ...results }): (ITest & { results: ITestResult[] })[] => {
            const foundTest = acc.find((el) => el.id === test.id);

            if (foundTest) {
              foundTest.results.push(results);
            } else {
              acc.push({ ...test, results: [results] });
            }

            return acc;
          },
          [] as (ITest & { results: ITestResult[] })[]
        );
        setTestsResults(reduced);
      });
    }
  }, [user]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {user && (
          <Box component={Paper} p={2}>
            <Typography variant="h3">{user.name}</Typography>
            <Typography variant="h6">{user.email}</Typography>
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        <CompanyUserTestResultsList data={testsResults} />
      </Grid>
      <Grid item xs={12}>
        <UserReportsList reports={rides} />
      </Grid>
    </Grid>
  );
};

export default CompanyUser;
