import i18n from "i18next";
import translation_en_sd from "./sd/en/translation.json";
import translation_pl_sd from "./sd/pl/translation.json";
import translation_de_sd from "./sd/de/translation.json";
import translation_en_sp from "./sp/en/translation.json";
import translation_pl_sp from "./sp/pl/translation.json";
import translation_de_sp from "./sp/de/translation.json";
import { initReactI18next } from "react-i18next";
import { getLanguage } from "../utils/languagePicker";

const resources_sd = {
  en: {
    translation: translation_en_sd
  },
  pl: {
    translation: translation_pl_sd
  },
  de: {
    translation: translation_de_sd
  }
} as const;

const resources_sp = {
  en: {
    translation: translation_en_sp
  },
  pl: {
    translation: translation_pl_sp
  },
  de: {
    translation: translation_de_sp
  }
} as const;

i18n.use(initReactI18next).init({
  lng: getLanguage(),
  //   fallbackLng: "en",
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  resources: process.env.REACT_APP_SITE === "sp" ? resources_sp : resources_sd
});
