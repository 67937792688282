import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Grid, Paper, Typography } from "@mui/material";
import { getCompanyTestStats } from "../../services/company.service";
import useAlerts from "../../hooks/useAlerts";
import PaperStepper from "../../components/PaperStepper";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrayLoadingSkeleton from "../../components/ArrayLoadingSkeleton";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import HeaderWithIcon from "../../components/HeaderWithIcon";
import ITestWithResults from "../../types/ITestWithResults";
import ITestResultWithUser from "../../types/ITestResultWithUser";
import { getStatsFromResults } from "../../utils/statsUtils";
import IUserWithResults from "../../types/IUserWithResults";
import CompanyTestResultsList from "./test/CompanyTestResultsList";
import CompanyTestResultsStats from "./test/CompanyTestResultsStats";

const groupResults = (results: ITestResultWithUser[]): IUserWithResults[] => {
  const userResults = Object.values(
    // Object.groupBy(data.results, (result: ITestResultWithUser) => result.user.id)
    results.reduce((acc: { [key: string]: ITestResultWithUser[] }, result: ITestResultWithUser) => {
      acc[`${result.user.id}`] = acc[`${result.user.id}`] || [];
      acc[`${result.user.id}`].push(result);
      return acc;
    }, {})
  );

  return userResults.map((values: ITestResultWithUser[]) => {
    return {
      ...values[0].user,
      ...getStatsFromResults(values),
      results: values
    };
  });
};

const CompanyTestStats = (): JSX.Element => {
  const { t } = useTranslation();
  const { error } = useAlerts();
  const { id, testId } = useParams();

  const [test, setTest] = useState<ITestWithResults>();
  const [results, setResults] = useState<IUserWithResults[]>();

  useEffect(() => {
    if (id && testId) {
      getCompanyTestStats(id, testId)
        .then(({ data }) => {
          setTest(data);
          setResults(groupResults(data.results));
        })
        .catch(() => {
          error();
        });
    }
  }, [id, testId]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PaperStepper steps={[t("Wybierz egzamin"), t("Statystyki egzaminu")]} step={1} />
      </Grid>

      <Grid item xs={12}>
        <ArrayLoadingSkeleton data={[test]}>
          <Paper>
            <Box padding={2}>
              <Typography gutterBottom={false} variant="h5">
                {t("Statystyki egzaminu")}: {test?.name ?? ""}
              </Typography>
            </Box>
          </Paper>
        </ArrayLoadingSkeleton>
      </Grid>

      <Grid item xs={12}>
        <CompanyTestResultsStats test={test} />
      </Grid>

      {/* <Grid item xs={12}>
        <ArrayLoadingSkeleton data={results}>
          <HeaderWithIcon title={"Wyniki"} count={results?.length} icon={AssessmentOutlinedIcon} />
        </ArrayLoadingSkeleton>
      </Grid> */}

      <Grid item xs={12}>
        <CompanyTestResultsList results={results} />
      </Grid>
    </Grid>
  );
};

export default CompanyTestStats;
