import React from "react";
import { Avatar, Box, Grid, Paper, Stack, Typography, styled } from "@mui/material";
import { ICardGroupWithItems } from "../../../../types/ICardGroup";
import { getLanguage } from "../../../../utils/languagePicker";
import { getStatsColorByResult, getStatsSmileByResult } from "../../../../utils/statsUtils";

const InnerBorderBox = styled(Box)(({ theme }) => ({
  position: "relative",
  "&:before": {
    content: '""',
    display: "block",
    position: "absolute",
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    width: `calc(100% - ${theme.spacing(2)})`,
    height: `calc(100% - ${theme.spacing(2)})`,
    top: theme.spacing(1),
    left: theme.spacing(1),
    zIndex: -1
  }
}));

const RideGroupElement = ({
  points,
  group,
  index,
  children
}: {
  points?: number;
  group: ICardGroupWithItems;
  index: number;
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const maxPoints = group.items.reduce((acc, item) => acc + item.points, 0);
  const groupPercent = Math.round(((points ?? 0) / maxPoints) * 100);
  const Smile = getStatsSmileByResult(groupPercent);

  return (
    <InnerBorderBox>
      <Paper sx={{ p: 2 }}>
        <Stack direction={"row"} alignItems={"center"}>
          <Box mr={2}>
            {points ? (
              <Avatar
                sx={() => ({
                  fontSize: "0.9rem",
                  bgcolor: getStatsColorByResult(groupPercent)
                })}
              >
                <Smile />
              </Avatar>
            ) : (
              <Avatar sx={(theme) => ({ bgcolor: theme.palette.primary.light })}>{index}</Avatar>
            )}
          </Box>
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="h3">{group.name[getLanguage()]}</Typography>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent={"center"}>
              <Typography variant="h3">{points && `${points}/${maxPoints}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">{group.description}</Typography>
            </Grid>
          </Grid>
        </Stack>
      </Paper>
      {children}
    </InnerBorderBox>
  );
};

export default RideGroupElement;
