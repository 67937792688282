import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import Breadcrumbs from "../../../components/Breadcrumbs";
import MenuIcon from "@mui/icons-material/Menu";
import { Stack } from "@mui/system";
import { AvatarImage } from "../../../components/AvatarImage";
import LanguagePicker from "../../../components/LanguagePicker";
import { color } from "../../../themes/globals";

const drawerWidth = 248;

export default function DashboardNavbar({
  onSidebarOpen
}: Readonly<{ onSidebarOpen: () => void }>) {
  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: color.white,
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` }
      }}
    >
      <Toolbar>
        <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
          <IconButton onClick={onSidebarOpen} sx={{ display: { md: "none" } }}>
            <MenuIcon fontSize="medium" />
          </IconButton>
          <Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
            <Breadcrumbs />
          </Box>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <LanguagePicker />
            <AvatarImage />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
