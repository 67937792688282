import React, { useState } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { IPasswordRecovery } from "../../../types/IUser";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import useAlerts from "../../../hooks/useAlerts";
import { color } from "../../../themes/globals";
import { changePassword } from "../../../services/user.service";
import { useTranslation } from "react-i18next";

export default function PasswordSettings({ data }: { data: number }) {
  const { t } = useTranslation();
  const { error, success } = useAlerts();
  const [password, setPassword] = useState<IPasswordRecovery>();

  ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
    if (value !== password?.newPassword) {
      return false;
    }
    return true;
  });

  function handleChange(event: React.FormEvent<{ name: string; value: string }>) {
    setPassword({
      ...password,
      [event.currentTarget.name]: event.currentTarget.value
    });
  }

  function handleSubmit(a: React.FormEvent) {
    if (password) {
      delete password.repeatPassword;

      changePassword(password)
        .then((data) => {
          success(t("Zaktualizowano hasło"));
          setPassword({});
        })
        .catch((err) => {
          console.error(err);
          error(t("Nie zaktualizowano hasła"));
          setPassword({});
        });
    }
  }

  return (
    <Box sx={{ p: 3, pt: 0, pb: 3 }}>
      <Typography
        color={color.primary}
        sx={{
          textTransform: "uppercase",
          fontSize: ".8rem",
          fontWeight: "300"
        }}
      >
        {t("Zmiana hasła")}
      </Typography>
      <ValidatorForm onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(3, 1fr)"
            },
            gap: 1
          }}
          pb={4}
          pt={3}
        >
          <TextValidator
            label={t("Obecne hasło")}
            name="oldPassword"
            onChange={handleChange}
            value={password?.oldPassword || ""}
            variant="outlined"
            validators={["required"]}
            errorMessages={[t("Pole jest wymagane")]}
            type="password"
            fullWidth
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(3, 1fr)"
            },
            gap: 1
          }}
          pb={4}
          pt={3}
        >
          <TextValidator
            label={t("Nowe hasło")}
            name="newPassword"
            onChange={handleChange}
            value={password?.newPassword || ""}
            variant="outlined"
            validators={["required"]}
            errorMessages={[t("Pole jest wymagane")]}
            type="password"
            fullWidth
          />
          <TextValidator
            label={t("Powtórz hasło")}
            name="repeatPassword"
            onChange={handleChange}
            value={password?.repeatPassword || ""}
            variant="outlined"
            validators={["isPasswordMatch", "required"]}
            errorMessages={[t("Hasła nie są takie same"), t("Pole jest wymagane")]}
            type="password"
            fullWidth
          />
        </Box>
        <Grid>
          <Stack direction="row" pt={3} justifyContent="end">
            <Button
              variant="contained"
              type={"submit"}
              disabled={!password?.repeatPassword}
              sx={{ width: { xs: "100%", sm: "25%" } }}
            >
              {t("Zmień")}
            </Button>
          </Stack>
        </Grid>
      </ValidatorForm>
    </Box>
  );
}
