import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { color } from "../../../themes/globals";
import IUser from "../../../types/IUser";
import { styled } from "@mui/system";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
}));

export default function AvatarSettings({ data }: Readonly<{ data: IUser }>) {
  return (
    <StyledBox
      sx={{
        p: 2
      }}
    >
      <Avatar sx={{ bgcolor: color.primary, width: "3.6rem", height: "3.6rem", mb: 1 }}>
        <PermIdentityIcon fontSize="large" />
      </Avatar>
      <Typography variant={"h3"} color={color.white}>
        {data.name}
      </Typography>
      <Typography variant="h6" color={color.white}>
        {data.email}
      </Typography>
    </StyledBox>
  );
}
