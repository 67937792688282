import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export default function useAlerts() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return {
    success: (msg = t("Sukces!")) => enqueueSnackbar(msg, { variant: "success" }),
    error: (msg = t("Ups, coś poszło nie tak")) => enqueueSnackbar(msg, { variant: "error" }),
    info: (msg: string) => enqueueSnackbar(msg, { variant: "info" }),
    warning: (msg = t("Uwaga!")) => enqueueSnackbar(msg, { variant: "warning" })
  };
}
