import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import ICard from "../../../types/ICard";
import IUser from "../../../types/IUser";

const PickersSummary = ({
  pickedUser,
  pickedCard,
  onReset,
  onConfirm
}: {
  pickedCard?: ICard;
  pickedUser?: IUser;
  onReset: () => void;
  onConfirm: () => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const isDisabled = !pickedCard || !pickedUser;

  return (
    <Paper>
      <Box p={2}>
        <Typography variant="caption" gutterBottom>
          {t("Podsumowanie")}
        </Typography>

        <Grid container mb={2} rowSpacing={2}>
          <Grid item xs={2}>
            <Typography>{t("Uczestnik")}:</Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography>
              <strong>{pickedUser?.name}</strong>
            </Typography>
            <Typography>{pickedUser?.email}</Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography>{t("Nazwa karty")}:</Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography>
              <strong>{pickedCard?.name}</strong>
            </Typography>
          </Grid>
        </Grid>

        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Button variant="outlined" color="secondary" onClick={() => onReset()}>
            {t("Resetuj")}
          </Button>
          <Button disabled={isDisabled} variant="outlined" onClick={() => onConfirm()}>
            {t("Rozpocznij ewaluację")}
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
};

export default PickersSummary;
