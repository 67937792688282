import ArrayLoadingSkeleton from "../../../components/ArrayLoadingSkeleton";
import { useTranslation } from "react-i18next";
import IUser from "../../../types/IUser";
import { color } from "../../../themes/globals";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import ReactApexChart from "react-apexcharts";

export default function UsersCountOnTimeLine({ users }: Readonly<{ users: IUser[] | undefined }>) {
  const { t } = useTranslation();
  const theme = useTheme();

  const mappedUsers =
    users
      ?.map(({ createdAt, ...user }) => ({
        createdAt: new Date(createdAt),
        ...user
      }))
      .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime()) ?? [];

  const months = mappedUsers.reduce(
    ([acc, count], user): [Map<string, number>, number] => {
      const index = new Date(user.createdAt.getFullYear(), user.createdAt.getMonth()).getTime();

      acc.set(index.toString(), count + 1);

      return [acc, count + 1];
    },
    [new Map(), 0] as [Map<string, number>, number]
  )[0];

  return (
    <ArrayLoadingSkeleton data={users}>
      <Paper
        sx={{
          overflow: "hidden"
        }}
      >
        <Box position={"absolute"} padding={2}>
          <Typography gutterBottom={false} variant="h3">
            {(users?.length ?? 0) + ""}
          </Typography>
          <Typography gutterBottom={false} variant="overline">
            {t("Uczestnicy")}
          </Typography>
        </Box>
        <ReactApexChart
          style={{ marginBottom: -2 }}
          height={124}
          //   width={"100%"}
          type="area"
          series={[
            {
              name: t("Uczestnicy"),
              data: Array.from(months.entries()).map(([x, y]) => ({ x, y }))
            }
          ]}
          options={{
            chart: {
              type: "area",
              width: "100%",
              offsetX: 0,
              offsetY: 0,
              parentHeightOffset: -10,
              zoom: { enabled: false },
              animations: { enabled: false },
              toolbar: { show: false },
              sparkline: { enabled: true }
            },
            legend: { show: false },
            markers: { size: 0 },
            dataLabels: { enabled: false },
            stroke: { curve: "smooth" },
            colors: [theme.palette.secondary.light],
            fill: {
              //   gradient: {
              //     type: "vertical",
              //     shadeIntensity: 1,
              //     // opacityFrom: 0.8,
              //     // opacityTo: 0.8
              //     stops: [0, 100],
              //     colorStops: [
              //       { color: color.primary, opacity: 0.7, offset: 0 },
              //       { color: "white", opacity: 0.7, offset: 100 }
              //     ]
              //   }
            },
            grid: {
              show: false,
              padding: { left: 0, bottom: 0, right: 0, top: 0 },
              xaxis: { lines: { show: false } },
              yaxis: { lines: { show: false } }
            },
            xaxis: {
              tickAmount: undefined,
              labels: { show: false },
              axisBorder: { show: false },
              axisTicks: { show: false },
              crosshairs: { show: false },
              tooltip: { enabled: false }
            },
            yaxis: {
              show: false,
              showAlways: false,
              opposite: false,
              reversed: false,
              logarithmic: false,
              tickAmount: undefined,
              labels: { show: false },
              axisBorder: { show: false },
              axisTicks: { show: false },
              crosshairs: { show: false },
              tooltip: { enabled: false }
            },
            tooltip: {
              enabled: true,
              shared: false,
              x: { show: false },
              marker: { show: false },
              followCursor: false
            }
          }}
        />
      </Paper>
    </ArrayLoadingSkeleton>
  );
}
