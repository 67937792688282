import React from "react";
import { Box, Drawer, List, Stack, Typography, useMediaQuery } from "@mui/material";
import { NavItem } from "./NavItem";
import { menuAdmin, menuCompanyOwner, menuTrainer, menu } from "../../../config/menu";
import { R_LOGOUT, R_SETTINGS } from "../../../config/routes";
import { getCompany, getTokenUser } from "../../../utils/tokenUtils";
import { useTranslation } from "react-i18next";
import IUser from "../../../types/IUser";
import systemLogo from "../../../utils/systemLogo";

const drawerWidth = 248;

export default function DashboardSidebar({
  open,
  onClose
}: Readonly<{
  open: boolean;
  onClose: () => void;
}>) {
  const { t } = useTranslation();

  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("md"), {
    defaultMatches: true,
    noSsr: false
  });

  const user = getTokenUser();
  const company = getCompany();

  function createLinks(user: IUser | null) {
    switch (user?.role) {
      case "User":
        return menu.map((item) => (
          <NavItem
            key={item.name}
            href={item.route}
            title={t(item.name)}
            // onClick={onClose}
          />
        ));
      case "Company":
        return menuCompanyOwner.map((item) => (
          <NavItem
            key={item.name}
            href={item.route}
            title={t(item.name)}
            // onClick={onClose}
          />
        ));
      case "Trainer":
        return menuTrainer.map((item) => (
          <NavItem
            key={item.name}
            href={item.route}
            title={t(item.name)}
            // onClick={onClose}
          />
        ));
      case "Admin":
        return menuAdmin.map((item) => (
          <NavItem
            key={item.name}
            href={item.route}
            title={t(item.name)}
            // onClick={onClose}
          />
        ));
    }
  }

  return (
    <Drawer
      anchor="left"
      variant={lgUp ? "permanent" : "temporary"}
      onClose={onClose}
      open={open}
      sx={{
        width: drawerWidth,
        flexShrink: 0
      }}
      PaperProps={{
        sx: {
          width: drawerWidth,
          boxSizing: "border-box"
        }
      }}
    >
      <Stack
        direction={"column"}
        justifyContent={"space-between"}
        alignItems={"stretch"}
        spacing={1}
        height={"100%"}
      >
        <Box>
          <Stack direction="column" justifyContent="center" alignItems="center" mb={2} mt={2}>
            <img src={systemLogo} alt="Logo SafeDrive" width={"20%"} />
            <Typography variant="subtitle1">{user?.name}</Typography>
            {company && (
              <Typography variant={"caption"} color="inherit">
                {company.name}
              </Typography>
            )}
          </Stack>
          <List>{createLinks(user)}</List>
        </Box>

        <Box>
          <NavItem
            href={R_SETTINGS}
            title={t("Ustawienia")}
            // onClick={onClose}
          />
          <NavItem
            href={R_LOGOUT}
            title={t("Wyloguj")}
            // onClick={onClose}
          />
        </Box>
      </Stack>
    </Drawer>
  );
}
