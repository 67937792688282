import { Paper, Step, StepButton, StepLabel, Stepper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const PaperStepper = ({
  steps = [],
  step = 0,
  onStep
}: {
  steps: string[];
  step?: number;
  onStep?: (step: number) => void;
}): JSX.Element => {
  const isFunction = typeof onStep === "function";

  return (
    <Box p={2} component={Paper} sx={{ overflow: "hidden" }}>
      <Stepper alternativeLabel={true} activeStep={step}>
        {steps.map((label, index) => (
          <Step key={index}>
            {isFunction ? (
              <StepButton onClick={() => onStep(index)}>{label}</StepButton>
            ) : (
              <StepLabel>{label}</StepLabel>
            )}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default PaperStepper;
